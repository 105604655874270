import React, { useState, useEffect } from 'react';
import { NavigationScreenProps } from '@src/navigators/NavigatorTypes';
import { selectAuthLoginInput } from '@src/ducks/slices/auth.slice';
import { useNavigation } from '@react-navigation/native';
import { TextInput02 } from '@src/components/textinput';
import { useAppSelector } from '@src/ducks/ducksHook';
import { Button01 } from '@src/components/button';
import { useAuthService } from '@src/ducks/hooks';
import { Text, View } from 'react-native';
import useStyles from './styles.css';
import Forgot from './remember-me';
import ErrorView from './error';
import images from '@assets/images';
import { mobileSizeChecker } from '@src/utils/screensize-helper';
import { ScrollView } from "react-native-gesture-handler";
import useOrientationHelper from "@src/utils/orientation-helper";
import _ from 'lodash';
import ActionButton from './action-button';

interface ErrorProps {
  email: string;
  password: string;
}

const Login = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const errorRef = React.useRef<any>(null);
  const isLandScape = useOrientationHelper();
  const loginInput = useAppSelector(selectAuthLoginInput);
  const [error, setError] = useState<ErrorProps>({} as ErrorProps);
  const { onSignin, isLoading, onResetLoading, setLoginInput } = useAuthService();

  const onChangeInput = (type: string) => (value: any) => {
    setLoginInput({ type, value });
    setError({} as ErrorProps);
    errorRef.current?.onClear();
  };

  const onSigningIn = () => {
    let newError: any = {};
    if (!loginInput.email) {
      newError.email = 'Email is required.';
    }
    if (!loginInput.password) {
      newError.password = 'Password is required.';
    }

    setError(newError);

    if (_.isEmpty(newError)) {
      onSignin();
    }
  };

  const onSignInWithFaceID = () => {
    const newError: any = {};
    if (!loginInput.email) {
      newError.email = "Email is required.";
    }

    setError(newError);

    if (_.isEmpty(newError)) {
      navigation.navigate("SignInWithFaceID");
    }
  };

  useEffect(() => {
    onResetLoading();
  }, []);

  return (
    <View style={isLandScape ? styles.v_login_landscape : styles.v_login}>
      <ScrollView showsVerticalScrollIndicator={false} 
        style={styles.scrollview}>
      <View style={isLandScape ? styles.v_height_top_landscape : styles.v_height_top} />
      <View style={styles.signInCenter}>
        <Text style={styles.txtNote1}>Hello again!</Text>
        <Text style={styles.txtNote2}>We're glad you're back. Please sign in to access your account</Text>
      </View>
      <ErrorView ref={errorRef} />
      <View style={styles.v_field}>
        <TextInput02
          label="Email"
          error={error.email}
          value={loginInput.email}
          placeholder="Enter your email address here"
          onChangeText={onChangeInput("email")}
          labelStyle={styles.labelInputStyle1}
          style={styles.labelInput}
        />
        <TextInput02
          hasPassword
          label="Password"
          error={error.password}
          value={loginInput.password}
          placeholder="Enter your password here"
          onChangeText={onChangeInput("password")}
          labelStyle={styles.labelInputStyle}
        />
        <Forgot 
          isCheck={loginInput?.rememberme}
          onCheck={() => onChangeInput("rememberme")(!loginInput?.rememberme)} />
        <ActionButton
          email={loginInput.email}
          isLoading={isLoading}
          onSigningIn={onSigningIn}
          onSignInWithFaceID={onSignInWithFaceID}
        />
        <Text style={styles.t_signup}>
          Don’t have an account yet?{' '}
          <Text style={styles.t_signup_2} onPress={() => navigation.navigate('Signup')}>Register here</Text>
        </Text>
      </View>
      </ScrollView>
    </View>
  );
};

export default Login;
