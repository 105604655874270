import React from 'react';
import { View } from 'react-native';
import CopyRightContent from './copy-right';
import Background from './background';
import useStyles from './styles.css';
import Header from './header';
import Login from './login';
import Logo from './logo';
import { mobileSizeChecker } from '@src/utils/screensize-helper';

const SignIn = () => {
  const styles = useStyles();
  const isMobile = mobileSizeChecker();
  return (
    <>
      {isMobile ? (
        <View style={styles.container}>
          <Background />
          <Header />
          <View style={styles.v_body}>
            <Logo />
            <Login />
          </View>
          <CopyRightContent />
        </View>
      ) : (
        <View style={styles.container}>
          <Background />
          <Header />
          <View style={styles.v2_body}>
            {/* <Screens /> */}
            <Logo />
            <Login />
          </View>
        </View>
      )}
    </>
  );
};
export default SignIn;
