import React, { useEffect } from 'react';
import { useRoute } from '@react-navigation/native';
import WitnessSignature from './witness-signature';
import { HeaderContext } from './provider/header';
import { ScrollView, View } from 'react-native';
import Loading from '@src/components/loading';
import useStyles from './styles.css';
import { useAppSelector } from '@src/ducks/ducksHook';

// SCREENS
const Identification = React.lazy(() => import('./identification'));
const VideoSession = React.lazy(() => import('./video-session'));
const ReviewDetails = React.lazy(() => import('./witness-signature/review-send'));
const WaitingApproval = React.lazy(() => import('./waiting-approval'));
const Documents = React.lazy(() => import('./documents'));
const Review = React.lazy(() => import('./review'));
const ChooseNotaryPublic = React.lazy(() => import('./choose-notary-public'));
import { selectedAuthRole } from '@src/ducks/slices/auth.slice';

const Screens = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const routeName: any = route.name; // Get Nested Route Name
  const { activeIndex, onSetCurrentPage } = React.useContext(HeaderContext);
  const role = useAppSelector(selectedAuthRole);

  const components = React.useMemo(() => {
    if (role === 'principal') {
      switch (activeIndex) {
        case 0:
          return <Identification />;
        case 1:
          return <Documents />;
        case 2:
          return <WitnessSignature />;
        case 3:
          return <ChooseNotaryPublic />;
        case 4:
          return <ReviewDetails />;
        case 5:
          return <WaitingApproval />;
        case 6:
          return <VideoSession />;
        case 7:
          return <Review />;
      }
    } else {
      switch (activeIndex) {
        case 0:
          return <VideoSession />;
        case 1:
          return <Review />;
      }
    }
  }, [activeIndex]);

  useEffect(() => {
    if (routeName === 'VideoSession') {
      if (role === 'principal') {
        onSetCurrentPage(6);
      } else {
        onSetCurrentPage(0);
      }
    }else if (routeName === 'Review') {
      if (role === 'principal') {
        onSetCurrentPage(7);
      } else {
        onSetCurrentPage(1);
      }

    }
  }, [routeName]);

  return (
    <View style={styles.scrollview} >
      <React.Suspense fallback={<Loading />}>{components}</React.Suspense>
    </View>
  );
};

export default React.memo(Screens);
