import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import colors from '@assets/colors';

const useStyles = CreateResponsiveStyle(
  {
    r_style: {
      backgroundColor: colors.white,
      paddingVertical: 5,
      paddingHorizontal: 24,
      borderBottomWidth: 0,
      // alignItems: 'center',
      justifyContent: 'center',
    },
    c_style: {
      // alignItems: 'center',
      justifyContent: 'flex-start',
    },
    pending_status: {
      color: colors.white,
      backgroundColor: colors.red1,
      paddingVertical: 1,
      paddingHorizontal: 5,
      borderRadius: 4
    },
    rejected_status: {
      color: colors.white,
      backgroundColor: colors.orange1,
      paddingVertical: 1,
      paddingHorizontal: 5,
      borderRadius: 4
    },
    accepted_status: {
      color: colors.white,
      backgroundColor: colors.green1,
      paddingVertical: 1,
      paddingHorizontal: 5,
      borderRadius: 4
    },
    c_text_style: {
      fontSize: 14,
      fontFamily: 'DMSans',
      color: colors.darkGray,
    },
    v_center: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    actions_icon: { height: 18, width: 18, marginRight: 8 },
    row_last: {
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      zIndex: 5,
    },
  },
  {
    [DEVICE_SIZES.LG]: {
      c_text_style: { fontSize: 11, lineHeight: 12 },
      actions_icon: { height: 12, width: 12, marginRight: 8 },
    },
  }
);

export default useStyles;
