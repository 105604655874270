import colors from "@assets/colors";
import SVGIcon from "@assets/svg";
import Icon from "@expo/vector-icons/MaterialIcons";
import React, { useEffect } from "react";
import { StyleSheet, View } from "react-native";
import Animated, {
  Easing,
  Extrapolation,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withRepeat,
  withTiming,
} from "react-native-reanimated";
import { SignInContext } from "../provider";

const PulseAnimation = () => {
  const scale1 = useSharedValue(1);
  const scale2 = useSharedValue(1);
  const scale3 = useSharedValue(1);
  const { enableCamera, scanSuccess, scanFailed } = React.useContext(SignInContext);

  const pulseSequence1 = withTiming(2, {
    duration: 4500,
    easing: Easing.linear,
  });

  const pulseSequence2 = withDelay(
    1500,
    withTiming(2, { duration: 4500, easing: Easing.linear })
  );

  const pulseSequence3 = withDelay(
    3000,
    withTiming(2, { duration: 4500, easing: Easing.linear })
  );

  useEffect(() => {
    scale1.value = withRepeat(pulseSequence1, -1, false);
    scale2.value = withRepeat(pulseSequence2, -1, false);
    scale3.value = withRepeat(pulseSequence3, -1, false);
  }, []);

  const animatedStyle1 = useAnimatedStyle(() => {
    const opacity = interpolate(
      scale1.value,
      [1, 2],
      [1, 0],
      Extrapolation.CLAMP
    );

    return {
      transform: [{ scale: scale1.value }],
      opacity: opacity,
    };
  });

  const animatedStyle2 = useAnimatedStyle(() => {
    const opacity = interpolate(
      scale2.value,
      [1, 2],
      [1, 0],
      Extrapolation.CLAMP
    );

    return {
      transform: [{ scale: scale2.value }],
      opacity: opacity,
    };
  });

  const animatedStyle3 = useAnimatedStyle(() => {
    const opacity = interpolate(
      scale3.value,
      [1, 2],
      [1, 0],
      Extrapolation.CLAMP
    );

    return {
      transform: [{ scale: scale3.value }],
      opacity: opacity,
    };
  });

  const MiddleContent = React.useMemo(() => {
    if(scanSuccess){
      return <Icon name="check" size={80} color={"white"} />;
    }else if(scanFailed){
      return <SVGIcon name="face-sad" width={80} height={80} />;
    }else{
      return <SVGIcon name="face-smile" width={80} height={80} />;
    }
  },[scanSuccess, scanFailed]);

  if(enableCamera && !scanSuccess){
    return null;
  }

  return (
    <>
      <Animated.View style={[styles.pulse, animatedStyle3]} />
      <Animated.View style={[styles.pulse, animatedStyle2]} />
      <Animated.View style={[styles.pulse, animatedStyle1]} />
      <View style={[styles.pulse]}>
        {MiddleContent}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  pulse: {
    width: 100,
    height: 100,
    borderRadius: 50,
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.purple4,
  },
});

export default PulseAnimation;
