import React from "react";
import { View, Pressable, Text, TouchableOpacity } from "react-native";
import { PluginContext } from '../../../screens/live-request/review/provider/plugin'
import useStyles from "./styles.css";
import colors from "@assets/colors";
import SVGIcon from "@assets/svg";
import Icon from '@assets/svg';
import { RenderZoomInProps, RenderZoomOutProps } from "@react-pdf-viewer/zoom";
import { DrawerContext } from '../../../screens/drawer-screens/DrawerProvider';
import { Divider } from "react-native-paper";

function HeaderPageNavigation() {
  const styles = useStyles();
  const { zoomPluginInstance, pageNavigationPluginInstance } = React.useContext(PluginContext);
  const { GoToNextPage, GoToPreviousPage, CurrentPageLabel } = pageNavigationPluginInstance;

  const { onActiveTab, activeTabDrawer } = React.useContext(DrawerContext);
  const { ZoomIn, ZoomOut, Zoom } = zoomPluginInstance;

  return (
  <View style={styles.container}>
    <TouchableOpacity
      onPress={() => onActiveTab(activeTabDrawer)}
      activeOpacity={0.9}
      style={styles.btn_style}
    >
      <Icon name="back" fill={colors.darkGray} />
    </TouchableOpacity>

    <View style={styles.btn_actions}>
      <ZoomIn>
        {(props: RenderZoomInProps) => (
          <Pressable style={styles.spacing} onPress={props.onClick}>
            <SVGIcon name="zoom_in_outlined" />
          </Pressable>
        )}
      </ZoomIn>

      <ZoomOut>
        {(props: RenderZoomOutProps) => (
          <Pressable style={styles.spacing} onPress={props.onClick}>
            <SVGIcon name="zoom_out_outlined" />
          </Pressable>
        )}
      </ZoomOut>

      <View style={[styles.zoom,]}>
        <Zoom levels={[0.4, 0.8, 1.2, 1.6, 2.4, 3.2]} />
      </View>
      

      <Divider style={styles.divider_vertical} />

      <GoToPreviousPage>
        {(props: any) => (
          <Pressable style={styles.spacing} onPress={props.onClick}>
            <SVGIcon name="arrow_up_outlined" />
          </Pressable>
        )}
      </GoToPreviousPage>

      <Text style={styles.page_label}>
        <CurrentPageLabel>
          {(props: any) => (
            <span>{`${props.currentPage + 1} of ${props.numberOfPages}`}</span>
          )}
        </CurrentPageLabel>
      </Text>

      <GoToNextPage>
        {(props: any) => (
          <Pressable style={styles.spacing} onPress={props.onClick}>
            <SVGIcon name="arrow_down_outlined" />
          </Pressable>
        )}
      </GoToNextPage>
    </View>
  </View>
  );
}

export default React.memo(HeaderPageNavigation);