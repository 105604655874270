import { StyleSheet } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import TypographyStyle from '@assets/typography';
import colors from '@assets/colors';
import { Dimensions } from 'react-native';
const windowHeight = Dimensions.get('window').height;

const useStyles = CreateResponsiveStyle(
  {
    container: { flex: 1, width: 459, },
    contentContainer: {
      flexGrow: 1,
      height: windowHeight / 1.35
    },
    marginChatbox: {
      marginTop: 0
    },
    header_label: StyleSheet.flatten([
      TypographyStyle.heading4Regular,
      { textAlign: 'left', color: colors.black3 },
    ]),
    removeElement: { 
      display: 'none'
    },
    inputStyles: {
      marginBottom: "0%"
    },
    cardContainer: {
      padding: 35,
      borderWidth: 0.5,
      borderRadius: 8,
      width: 923,
      marginTop: 40,
      backgroundColor: colors.white,
      borderColor: colors.border2,
    },
    maximizedContainer: {
      borderWidth: 0.5
    },
    maximizedSupportPage: {
      flex: 1,
      padding: 35,
      borderRadius: 0,
      backgroundColor: colors.white,
    },
    tabContainer: {
      borderRadius: 0,
      borderColor: colors.white,
      padding: 35,

    },
    marginV: {
      marginVertical: 20,
    },
    cardHeader: {
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: StyleSheet.flatten([
      TypographyStyle.Body2Regular,
      { color: colors.black3, textAlign: 'center', fontWeight: '900', fontSize: 13, padding: 1 },
    ]),
    titleViewWeb: StyleSheet.flatten([
      TypographyStyle.Body2Regular,
      { color: colors.black3, textAlign: 'left', fontWeight: '700', fontSize: 16 },
    ]),
    flex1: { flex: 1 },
    alignCenter: {alignItems: 'center'},
    message: {
      height: 141,
    },
    chooseFile: {
      backgroundColor: 'white',
      borderWidth: 1,
      borderColor: colors.lightPurple,
      marginHorizontal: 10,
      margin: 5
    },
    fieldText: StyleSheet.flatten([
      TypographyStyle.Body2Regular, 
      { 
        textAlign: 'center',
        fontSize: 13,
      }
    ]),
    fieldTextWeb: StyleSheet.flatten([TypographyStyle.Body2Regular, {  marginBottom: 5 }]),
    fileNames: {
      width: 110
    },
    v_actions: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginTop: 15,
      margin: 1
    },
    v_actions_web: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 30,
      margin: 1
    },
    v_upload_web: {
      flexDirection: 'row',
      alignItems: 'center',
      textAlign: 'center',
      flexWrap: 'wrap',
    },
    v_upload: {
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      flexWrap: 'wrap'
    },
    v_extraheight: { height: 40 },
    btnCotainer: {
      // width: '59%',
      alignItems: 'flex-end'
    },
    btnSubmitWeb: { width: 171 },
    btn_submit: {},
    header: {
      backgroundColor: '#6767BB',
      padding: 10,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    headerText: StyleSheet.flatten([TypographyStyle.buttonText,
      {
        fontSize: 18,
        fontWeight: '500',
        textAlign: 'center',
        color: '#FFFFFF',
        marginLeft: 20
      }
    ]),
    icons: {
      flexDirection: 'row',
      padding: 5
    },
    icon: {
      padding: 5
    },
    fileContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 5,
      paddingRight: 10
    },
    deleteIcon: {
      marginLeft: 5,
    },
    btnSubmit: { width: '100%', marginTop: 20, marginBottom: 10 },
    upload_mobile: { flexDirection: 'column', alignItems: 'center'},
    upload_chatbox: { flexDirection: 'row', alignItems: 'center'},
    faqs_redirect: StyleSheet.flatten([TypographyStyle.Body2Regular, 
      { 
        textAlign: 'center', 
        color: colors.black3, 
        fontWeight: '700',
        marginTop: 30,
      }
    ]),
    faqs_clickhere: StyleSheet.flatten([TypographyStyle.Body2Regular,
      {
        textAlign: 'center', 
        color: colors.black3,
        marginBottom: 30,
      }
    ]),
    faqs_container: { 
      width: '100%', 
      marginTop: 30,
      marginBottom: 30,
      borderTopWidth: 0.5,
      borderTopColor: colors.border6,
    },
    faqs_btn: { backgroundColor: colors.purple4},
    itemSeparator: {
      height: 1,
      marginHorizontal: 10,
      backgroundColor: colors.offwhite,
    },
    inputMargin: {
      marginTop: '5%'
    },
    tabletContainerSize: {},
    fieldsTitle: StyleSheet.flatten([TypographyStyle.fieldsTitle]),
  },
  {
    [DEVICE_SIZES.LARGE_DEVICE]: {
      header_label: { fontSize: 20, lineHeight: 16 },
      contentContainer: {
        height: '100%'
      },
      tabletContainerSize: {
        height: 500
      }
    },
    [DEVICE_SIZES.MD]: {
      contentContainer: {
        height: '100%',
      },
      tabletContainerSize: {
        height: 700,
      },
      cardContainer: {
        borderWidth: 0,
        backgroundColor: colors.transparent,
        padding: 35,
        width: "100%"
      },
      header_label: {
        display: "none"
      },
      btnSubmit: {
        width: '100%', 
        alignItems: 'center',
      },
      fieldText: {
        width: 200
      },
      inputMargin: {
        marginTop: '25%'
      },
      inputStyles: {
        marginBottom: "0%"
      },
    },
    [DEVICE_SIZES.SM]: {
      container: { width: '100%', paddingHorizontal: 10 },
      title: { fontSize: 16 },
      cardContainer: {
        borderWidth: 0,
        backgroundColor: colors.transparent,
        padding: 35,
        width: "100%"
      },
      inputStyles: {
        marginBottom: "0%"
      },
      header_label: { marginBottom: 22 },
      fieldText: { fontSize: 13 },
      fieldTextWeb: { textAlign: 'center' },
      v_upload_web: { flexDirection: 'column' },
      v_actions_web: { flexDirection: 'column' },
    },
    [DEVICE_SIZES.XS]: {
      container: { width: '100%', paddingHorizontal: 10 },
      title: { fontSize: 16 },
      cardContainer: {
        borderWidth: 0,
        backgroundColor: colors.transparent,
        padding: 35,
        width: "100%"
      },
      inputStyles: {
        marginBottom: "10%"
      },
      header_label: { marginBottom: 22 },
      fieldText: { fontSize: 13 },
      fieldTextWeb: { textAlign: 'center' },
      v_upload_web: { flexDirection: 'column' },
      v_actions_web: { flexDirection: 'column' },
    },
  }
);

export default useStyles;
