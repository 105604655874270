import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles';
import { Dimensions } from "react-native";
import colors from '@assets/colors';
const windowWidth = Dimensions.get("window").width;
import TypographyStyle from "@assets/typography";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      flexDirection: 'row',
      backgroundColor: colors.white,
    },
    linear_style: {
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    scrollView: {
      paddingHorizontal: 70
    },
    contentButton: {
      marginLeft: 20,
      width: 200, 
      height: 50, 
      flexDirection: "row",
      alignItems:"center", 
      alignContent: "center", 
      justifyContent: "center"
    },
    chatButton: {
      alignItems: "flex-end",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      borderBottomLeftRadius: 20,
      backgroundColor: '#6767BB'
    },
    drawer_mask: {
      width: "100%", height: 1080, 
      backgroundColor: colors.blurBG, 
      zIndex: 2, position: "absolute"
    },
    signSecureSupport:{
      ...TypographyStyle.heading1Bold,
    },
    chatSupportBtn : {
      position: "absolute", 
      right: 70, 
      bottom: 5
    },
    chatContainer: {
      borderWidth: 0.5,
      backgroundColor: "#ffffff",
      borderColor: colors.grayPrimary,
      width: windowWidth / 4,
      position: "absolute",
      bottom: 0,
      right: 0,
    },
    tabletContainer: {
      width: windowWidth / 2,
      borderColor: colors.white
    }
  },
  {
    [DEVICE_SIZES.LG]: {
      scrollView: { paddingHorizontal: 50 },
    },
    [maxSize(DEVICE_SIZES.MD)]: {
      scrollView: { paddingHorizontal: 0 },
      container : {flexDirection: "column"},
      chatSupportBtn : {
        position: "absolute", 
        right: 0, 
        bottom: 0
      },
    },
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;
