import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { StyleSheet } from 'react-native';
import TypographyStyle from '@assets/typography';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      overflow: 'hidden',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.colorPrimary,
    },
    v_height_top_landscape: {height: "15%"},
    v_height_top: { height: "10%" },
    scrollview: { flex: 1, width: '100%' },
    v_body: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
    },
    v2_body: {
      width: '100%',
      height: '100%',
      flexDirection: 'row',
    },
    v_login: {
      // padding: 25,
      // minWidth: 450,
      // maxWidth: 650,
      // minHeight: 500,
      // borderWidth: 1,
      // borderRadius: 8,
      // paddingHorizontal: 100,
      // borderColor: colors.white,
      // backgroundColor: colors.white,
      width: "50%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      paddingHorizontal: "10%",
      backgroundColor: colors.white,
    },
    signInCenter: { alignItems: 'center', marginTop: 60 },
    txtNote1: StyleSheet.flatten([TypographyStyle.heading2Bold]),
    v_field: { marginTop: 20, marginBottom: 15 },
    txtNote2: StyleSheet.flatten([
      TypographyStyle.heading4Regular,
      { marginTop: 16 },
    ]),
    t_error: StyleSheet.flatten([
      TypographyStyle.ButtonLRegular,
      { marginTop: 13, color: colors.red, fontSize: 15, textAlign: 'center' },
    ]),
    t_signup: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        marginTop: 50,
        textAlign: 'center',
        alignSelf: 'center',
        color: colors.black3,
      },
    ]),
    t_signup_2: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        color: colors.purple,
        fontWeight: '700',
      },
    ]),
    btn_signin: {
      marginTop: 30,
      backgroundColor: colors.purple4,
      width: '100%'
    },

    btn_cancel: {
      marginTop: 15,
      backgroundColor: colors.lightPurple,
    },
    lbl_signin: {
      fontFamily: 'Montserrat-Bold',
    },
    emailText: StyleSheet.flatten([
      TypographyStyle.heading6Regular,
        {color: colors.black1}
    ]),
    v_login_landscape: {
      width: "50%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      paddingHorizontal: "10%",
      backgroundColor: colors.white,
    },

  },
  {
    [DEVICE_SIZES.XL]: {
      t_signup: StyleSheet.flatten([
        TypographyStyle.fieldsTitle,
        {
          marginTop: 150,
          textAlign: 'center',
          alignSelf: 'center',
        },
      ]),
      txtNote1: StyleSheet.flatten([TypographyStyle.heading2Bold, {
        fontSize: 36,
        color: colors.black2
      }]),
      txtNote2: StyleSheet.flatten([
        TypographyStyle.heading4Regular,
        { marginTop: 16, fontSize: 20 },
      ]),
    },
    [DEVICE_SIZES.LG]: {
      v_body: {
        width: '100%',
      },
      container: {
        flex: 1,
        transform: [{ scaleX: 1.1 }],
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.colorPrimary,
      },
      scrollview: { flex: 1, width: '100%' },
      t_error: { fontSize: 13, lineHeight: 20 },
      txtNote1: {
        fontSize: 24,
        lineHeight: 25,
      },
      txtNote2: {
        marginTop: 4,
        fontSize: 16,
        lineHeight: 20,
      },
    },
    [DEVICE_SIZES.MD]: {
      v_body: {
        width: '80%',
      },
      v_login_landscape: {
        width: "60%",
        height: "60%",
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: "10%",
        marginRight: 'auto',
        marginLeft: 'auto',
        backgroundColor: colors.white,
      },
      signInCenter: { alignItems: 'center', marginTop: 0 },
      v_login: {
        minWidth: '100%',
        maxWidth: '100%',
        marginBottom: 20
      },
      t_error: { fontSize: 13, lineHeight: 20 },
      txtNote1: {
        fontSize: 22,
        lineHeight: 25,
      },
      txtNote2: {
        marginTop: 4,
        fontSize: 16,
        lineHeight: 20,
      },
    },
    [DEVICE_SIZES.SM]: {
      v_body: {
        width: '80%',
        alignItems: 'flex-start',
        marginHorizontal: 20,
        marginBottom: 40,
      },
      v_login_landscape: {
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: "10%",
        backgroundColor: colors.white,
      },
      signInCenter: {marginTop: -20},
      v_login: {
        minWidth: '100%',
        maxWidth: '100%',
        paddingHorizontal: 80,
      },
      t_error: { fontSize: 13, lineHeight: 20 },
      txtNote1: {
        fontSize: 22,
        lineHeight: 25,
      },
      txtNote2: {
        marginTop: 4,
        fontSize: 16,
        lineHeight: 20,
      },
    },
    [DEVICE_SIZES.XS]: {
      v_body: {
        width: '90%',
        alignItems: 'flex-start',
        marginHorizontal: 20,
        marginBottom: 40,
      },
      signInCenter: {marginTop: 0},
      v_login_landscape: {
        width: "50%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: "10%",
        backgroundColor: colors.white,
      },
      v_login: {
        minWidth: '100%',
        maxWidth: '100%',
        paddingHorizontal: 20,
      },
      t_error: { fontSize: 13, lineHeight: 20 },
      txtNote1: {
        fontSize: 20,
        lineHeight: 25,
      },
      txtNote2: {
        marginTop: 4,
        fontSize: 14,
        lineHeight: 20,
      },
      scrollview: {flex: 1, width: '100%'},
      t_signup: {marginTop: 10, marginBottom: 10},
      lbl_signin: {marginTop: 15}
    },
  }
);

export default useStyles;
