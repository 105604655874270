import React, { useState, useEffect } from 'react';
import { TextInput02 } from '@src/components/textinput';
import { useRoute } from '@react-navigation/native';
import { useForgotService } from '@src/ducks/hooks';
import { Button01 } from '@src/components/button';
import ErrorView from '../forgot-password/error';
import { Text, View } from 'react-native';
import useStyles from './styles.css';
import _ from 'lodash';

interface ErrorProps {
  newpassword: string;
  confirmpassword: string;
}

let ErrorValue = {
  newpassword: '',
  confirmpassword: '',
};

const ResetPassword = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const token = route.params.token;
  const errorRef = React.useRef<any>(null);
  const { isLoading, onReset } = useForgotService();
  const [error, setError] = useState<ErrorProps>(ErrorValue);
  const [newpassword, setNewpassword] = useState<string>('');
  const [confirmpassword, setConfirmpassword] = useState<string>('');

  const onResetPassword = () => {
    let newError: any = {};
    if (!newpassword) {
      newError.newpassword = 'New Password is required.';
    }

    if (!confirmpassword) {
      newError.confirmpassword = 'Confirm Password is required.';
    }

    setError(newError);

    if (_.isEmpty(newError)) {
      onReset({
        resetToken: token,
        password: newpassword,
        confirmPassword: confirmpassword,
      });
    }
  };

  useEffect(() => {
    setError({} as ErrorProps);
    errorRef.current?.onClear();
  }, [newpassword]);

  return (
    <View style={styles.v2_login}>
      <View style={styles.signInCenter}>
        <Text style={styles.txtNote1}>Reset your password</Text>
        <Text style={styles.txtNote2}>
          Please input a new password that you haven’t used before
        </Text>
      </View>
      <ErrorView ref={errorRef} />
      <View style={styles.v_field}>
        <TextInput02
          hasPassword
          value={newpassword}
          label="New Password"
          error={error.newpassword}
          onChangeText={setNewpassword}
          placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
          labelStyle={{fontSize: 16}}
        />
        <TextInput02
          hasPassword
          value={confirmpassword}
          label="Confirm Password"
          error={error.confirmpassword}
          onChangeText={setConfirmpassword}
          placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
          labelStyle={{fontSize: 16}}
        />
        <Button01
          label="Change Password"
          color={'white'}
          isLoading={isLoading}
          onPress={onResetPassword}
          style={[styles.btn_signin]}
          labelStyle={styles.lbl_signin}
        />
        <View style={styles.v_height} />
      </View>
    </View>
  );
};

export default ResetPassword;
