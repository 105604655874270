import React from 'react';
import { mobileSizeChecker } from '@src/utils/screensize-helper';
import { DataTable } from 'react-native-paper';
import CustomCell from './custom-cell';
import images from '@assets/images';
import useStyles from './styles';

type DataItemProps = {
  item: any;
  headers: any[];
  islast: boolean;
  disabled?: boolean;
  renderMobile?: any;
  onPress?: () => void;
  onPressItem?: () => void;
};

const DataItem = (props: DataItemProps) => {
  const styles = useStyles();
  const isMobile = mobileSizeChecker();
  const { islast, item, headers, renderMobile } = props;

  if (isMobile && typeof renderMobile === 'function') {
    return props.renderMobile(props);
  }

  return (
    <DataTable.Row
      onPress={props.onPress}
      disabled={props.disabled}
      pointerEvents="box-none"
      style={[styles.r_style, islast && styles.row_last]}
    >
      {headers.map((headerItem: any, index: number) => {
        if (headerItem.icon) {
          return (
            <CustomCell
              key={index.toString()}
              style={[
                styles.c_style,
                headerItem.cellStyle && headerItem.cellStyle,
                headerItem.align && {
                  justifyContent: headerItem.align,
                },
              ]}
              textStyle={[
                styles.c_text_style,
                headerItem.style && headerItem.style,
                headerItem.color && { color: headerItem.color },
              ]}
              disabled={headerItem.buttonEnable}
              source={images[headerItem.icon]}
              onPress={() =>
                typeof props.onPressItem === 'function'
                  ? props?.onPressItem()
                  : null
              }
              value={headerItem.value || item[headerItem.id]}
              iconStyle={headerItem.iconStyle || styles.actions_icon}
            />
          );
        }
        return (
          <DataTable.Cell
            key={index.toString()}
            style={[
              styles.c_style,
              headerItem.cellStyle && headerItem.cellStyle,
              headerItem.align && {
                justifyContent: headerItem.align,
              },
            ]}
            textStyle={[
              item[headerItem.id] == 'Pending'
                ? styles.pending_status
                : item[headerItem.id] == 'Rejected'
                ? styles.rejected_status
                : item[headerItem.id] == 'Accepted'
                ? styles.accepted_status
                : styles.c_text_style,
              headerItem.style && headerItem.style,
              headerItem.color && { color: headerItem.color },
            ]}
          >
            {item[headerItem.id]}
          </DataTable.Cell>
        );
      })}
    </DataTable.Row>
  );
};

export default React.memo(DataItem);
