import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { RootStackParamList, RootStackOptionsValue } from './NavigatorTypes';

// REDUCER
import { selectAuthLoggedIn } from '@src/ducks/slices/auth.slice';
import { useAppSelector } from '@src/ducks/ducksHook';

// COMPONENT SCREENS
import SignIn from '@src/screens/auth/signin';
import SignInBackAgain from '@src/screens/auth/signin-back';
import SignUp from '@src/screens/auth/signup';
import SignInFaceID from '@src/screens/auth/signin-face-id';
import VerificationSuccess from '@src/screens/auth/verification/success';
import VerificationFailed from '@src/screens/auth/verification/failed';
import VerificationCheck from '@src/screens/auth/verification';
import TermsCondition from '@src/screens/auth/terms-condition';
import PrivacyPolicy from '@src/screens/auth/privacy-policy';
import Forgot from '@src/screens/auth/forgot-password';
import ResetPassword from '@src/screens/auth/reset-password';
import Homepage from '@src/screens/drawer-screens';
import LiveRequest from '@src/screens/live-request';
import TEST from './test';
import Transaction from '@src/screens/drawer-screens/transactions';
import Popup from '@src/screens/drawer-screens/signatures/upload';

const RootStack = createStackNavigator<RootStackParamList>();

const RootStackOptions: RootStackOptionsValue = {
  headerShown: false,
  title: 'NotarizeIT',
  cardStyle: { backgroundColor: 'transparent' },
};

const AppRouting = () => {
  const isLoggedIn = useAppSelector(selectAuthLoggedIn);

  const screenComponents = React.useMemo(() => {
    if (isLoggedIn) {
      return (
        <>
          <RootStack.Screen name="Home" component={Homepage} />
          <RootStack.Screen name="LiveRequest" component={LiveRequest} />
          <RootStack.Screen name="VideoSession" component={LiveRequest} />
          <RootStack.Screen name="verify-success" component={VerificationSuccess} />
          <RootStack.Screen name="privacy-policy" component={PrivacyPolicy} />
          <RootStack.Screen name="terms-condition" component={TermsCondition} />
        </>
      );
    } else {
      return (
        <>
          <RootStack.Screen name="Signin" component={SignIn} />
          <RootStack.Screen name="SignInWithFaceID" component={SignInFaceID} />
          <RootStack.Screen name="SignInBack" component={SignInBackAgain} />
          <RootStack.Screen name="Signup" component={SignUp} />
          <RootStack.Screen name="Forgot" component={Forgot} />
          <RootStack.Screen name="ResetPassword" component={ResetPassword} />
          <RootStack.Screen name="verification-check" component={VerificationCheck} />
          <RootStack.Screen name="privacy-policy" component={PrivacyPolicy} />
          <RootStack.Screen name="terms-condition" component={TermsCondition} />
        </>
      );
    }
  }, [isLoggedIn]);

  return (
    <RootStack.Navigator screenOptions={RootStackOptions}>
      {screenComponents}
    </RootStack.Navigator>
  );
};

export default AppRouting;
