import { SagaIterator } from '@redux-saga/core';
import { call, put, select, takeEvery } from 'redux-saga/effects';

// API
import { userInfo } from '@src/utils/api';

// Slice
import { authActions, selectAuthSession } from '../slices/auth.slice';
import { userActions } from '../slices/user.slice';

function* handleUserDetails(): SagaIterator {
  try {
    const session = yield select(selectAuthSession)
    const user = yield call(userInfo, session);

    yield put(userActions.success(user.data));
  } catch (error: any) {
    const message = error.message || error.error || 'Something went wrong';
    yield put(userActions.failed({ message }));

    if (message.includes('Unauthorized')) {
      yield put(authActions.logout());
    }
  }
}

// Watcher Saga
function* userWatcherSaga(): SagaIterator {
  yield takeEvery(userActions.fetchUserDetails.type, handleUserDetails);
}

export default userWatcherSaga;
