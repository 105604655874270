// ***** RETAINING THIS OLD LOGO FOR SOME REASON ****** //

// import React from 'react';
// import { View, Image } from 'react-native';
// import { DEVICE_SIZES, useDeviceSize } from 'rn-responsive-styles';
// import useStyles from './styles.css';
// import { images } from 'theme';

// const Logo = () => {
//   const styles = useStyles();
//   const device_size = useDeviceSize();

//   if (DEVICE_SIZES.XS === device_size || DEVICE_SIZES.SM === device_size || DEVICE_SIZES.MD === device_size) {
//     return null;
//   }

//   return (
//     <View style={styles.v_logo_container}>
//       <View style={styles.signInCenter}>
//         <Image
//           source={images.enotary}
//           style={styles.i_logo}
//           resizeMode="contain"
//         />
//       </View>
//     </View>
//   );
// };

// export default Logo;

import React from 'react';
import { View, Image, Text } from 'react-native';
import { DEVICE_SIZES, useDeviceSize } from 'rn-responsive-styles';
import useStyles from './styles.css';
import { colors, images } from 'theme';
import SVGIcon from "@assets/svg";
import { largeSize, tabletSize  } from "@src/utils/screensize-helper";
import UnawaProduct from '../signin/unawa-product';

type LogoProps = {
  logoColor?: string;
  termsColor?: string;
  termsStyle?: any;
}

const Logo = (props: LogoProps) => {
  const styles = useStyles();
  const isTablet = tabletSize();
  const isLargeSize = largeSize();

  const size = React.useMemo(() => {
    if(isLargeSize){
      return {
        width: 373,
        height: 80,
      };
    } 
    if (isTablet){
      return {
        width: 300,
        height: 120,
      };
    }

    return {
      width: 373,
      height: 80,
    };
  },[isLargeSize]);

  return (
    <View style={styles.v2_logo_container}>
      <SVGIcon name="NotarizeLogo" {...size} fill={props.logoColor}  />
      <Text style={[styles.t_logo, { color: props.logoColor}]}>Remote notarization {"\n"}at your fingertips.</Text>
      {/* <CopyRightContent color={props.termsColor} termsStyle={props.termsStyle} /> */}
      <UnawaProduct />
    </View>
  );
};

export default Logo;
