import React from 'react';
import { mobileSizeChecker } from '@src/utils/screensize-helper';
import { LinearGradient } from 'expo-linear-gradient';
import { ImageBackground } from 'react-native';
import useStyles from './styles.css';
import images from '@assets/images';

const Background = () => {
  const styles = useStyles();
  const isMobile = mobileSizeChecker();

  return (
    <>
      <LinearGradient
        colors={['#45459B', '#575AA7', '#575AA7', '#575AA7']}
        style={[styles.linearStyle]}
      />
      <ImageBackground
        resizeMode="cover"
        resizeMethod="scale"
        source={isMobile ? images.bg_mask : images.v2_bg_mask}
        style={[styles.i_bg]}
      />
    </>
  );
};

export default Background;
