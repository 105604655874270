import { all, fork } from 'redux-saga/effects';
import authWatcherSaga from './auth.sagas';
import userWatcherSaga from './user.sagas';
import signsecureWatcherSaga from './signsecure.sagas';

export default function* rootSaga() {
  yield all([
    fork(authWatcherSaga),
    fork(userWatcherSaga),
    fork(signsecureWatcherSaga),
  ]);
}
