import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import colors from '@assets/colors';

export const STEPS_HEADER_HEIGHT = 91;

const useStyles = CreateResponsiveStyle(
  {
    container: {
      // top: 0,
      width: '100%',
      height: STEPS_HEADER_HEIGHT,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.white,
      borderBottomWidth: 0.5,
      paddingVertical: 13,
      borderBottomColor: colors.border7
    },
    btnIcon: {
      left: 64,
      position: 'absolute',
      zIndex: 2,
    },
    steps_container: {
      height: '100%',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    steps_outline: {
      flex: 1,
      top: 32,
      height: 1,
      position: 'absolute',
      justifyContent: 'center',
      backgroundColor: colors.grayPrimary,
    },
    steps_fillLine: {
      flex: 1,
      top: 32,
      height: 1,
      left: "7%",
      position: 'absolute',
      justifyContent: 'center',
      backgroundColor: colors.lightPurple,
    },
    v_align_center: {
      minWidth: 120,
      alignItems: 'center',
      justifyContent: 'center',
    },
    outer_circle: {
      paddingRight: 10,
      paddingLeft: 10,
      borderRadius: 50,
      paddingVertical: 5,
      backgroundColor: colors.white,
    },
    number_circle: {
      height: 28,
      width: 28,
      borderWidth: 1,
      borderRadius: 20,
      alignItems: 'center',
      justifyContent: 'center',
      borderColor: colors.grayPrimary,
      backgroundColor: 'transparent',
    },
    steps_number: {
      fontFamily: 'DMSans',
      fontWeight: '700',
      fontSize: 14,
      lineHeight: 14,
      color: colors.grayPrimary,
    },
    steps_label: {
      fontSize: 10,
      lineHeight: 17,
      fontFamily: 'DMSans-Bold',
      color: colors.grayPrimary,
    },
   
    btn_affix: {
      width: 163,
      height: 48,
      position: 'absolute',
      right: 60,
      borderRadius: 8,
      backgroundColor: colors.purple4
    },
    lbl_affix: {
      fontSize: 14,
      lineHeight: 16,
      fontFamily: 'Montserrat-bold',
      color: colors.white,
    },
    steps_number_active: {
      color: colors.white,
    },
    number_circle_active: {
      borderColor: colors.purple4,
      backgroundColor: colors.purple4,
    },
  },
  {
    [DEVICE_SIZES.LG]: {
      container: {
        height: 80,
        paddingVertical: 1,
        borderBottomWidth: 0.38,
      },
      outer_circle: {
        paddingRight: 8,
        paddingLeft: 8,
      },
      number_circle: {
        height: 21,
        width: 21,
      },
      steps_number: {
        fontSize: 11,
        lineHeight: 11,
      },
      steps_label: {
        fontSize: 8,
        lineHeight: 13,
      },
      btn_affix: {
        width: 123,
        height: 36,
        right: 50,
        borderRadius: 6,
      },
      lbl_affix: {
        fontSize: 11,
        lineHeight: 12,
      },
    },
  }
);

export default useStyles;
