import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import colors from '@assets/colors';

const useStyles = CreateResponsiveStyle(
  {
    main: {
      flex: 1,
    },
    container: {
      flex: 1,
    },
    scrollview: { 
      backgroundColor: colors.white,
      flex: 1,
    },
    v_height40: { height: 40 },
  },
  {
    [DEVICE_SIZES.LG]: {},
  }
);

export default useStyles;
