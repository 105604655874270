const LiveRequest = [
  'Identification',
  'Submit Document',
  'Add Participants',
  'Choose Notary',
  'Review details',
  'Waiting for approval',
  'Video session',
  'Review',
];

export default LiveRequest;
