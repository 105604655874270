import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { Linking, Text } from "react-native";
import useStyles from "./styles.css";
import SVGIcon from "@assets/svg";
import { tabletSize, mobileSize,  } from "@src/utils/screensize-helper";
import colors from "@assets/colors";

const UnawaProduct = ({ color, termsStyle }: any) => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const [ currentYear , setCurrentYear ] = useState("");
  const isTablet = tabletSize();
  const isMobile = mobileSize();

  useEffect (() => {
    const year = new Date().getFullYear();
    setCurrentYear(
      year + ""
    );
  }, []);

  return (
    <Text style={[styles.unawaProductStyle, termsStyle, { color }]}>
      {!isTablet && !isMobile ? <SVGIcon name="UnawaProduct"/> : <SVGIcon name="UnawaProductBlack"/>} {"\n"}

      {  "© " + currentYear + ". All rights reserved." } {isMobile ? "\n" : ""}
      <Text
        style={styles.copyRightBtnStyle}
        onPress={() => navigation.navigate("terms-condition")}
      >
        Terms & Conditions
      </Text>{"  "}
      |
      <Text
        style={styles.copyRightBtnStyle}
        onPress={() => navigation.navigate("privacy-policy")}
      >
        {"  "}
        Privacy Policy
      </Text>
    </Text>
  );
};

export default UnawaProduct;
