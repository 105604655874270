import { StyleSheet } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import TypographyStyle from '@assets/typography';
import colors from '@assets/colors';

const useStyles = CreateResponsiveStyle(
  {
    container: { flex: 1, width: '100%', paddingTop: 20, paddingHorizontal: 0 },
    header_label: StyleSheet.flatten([
      TypographyStyle.heading4Regular,
      {
        textAlign: 'left',
        color: colors.black3,
        marginVertical: 18,
        fontWeight: '700',
        fontSize: 16,
      },
    ]),
    v_separator: {
      height: 0.5,
      opacity: 0.1,
      backgroundColor: colors.gray1,
    },
    v_height: { height: 50 },
    inbox_row: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    inbox_status: {
      width: 8,
      height: 8,
      borderRadius: 4,
      backgroundColor: colors.red1,
      marginHorizontal: 20,
    },
    inbox_status_viewed: {
      width: 8,
      height: 8,
      borderRadius: 4,
      backgroundColor: colors.transparent,
      marginHorizontal: 20,
    },
    inbox_content: {
      flex: 1,
      marginVertical: 10,
    },
    inbox_text: {
      padding: 2,
      fontSize: 13,
    },
  },
  {
    [DEVICE_SIZES.LARGE_DEVICE]: {
      header_label: { fontSize: 20, lineHeight: 16 },
    },
    [DEVICE_SIZES.SM]: {
      header_label: { marginBottom: 22 },
      v_separator: {
        height: 0.5,
        opacity: 0.3,
        backgroundColor: colors.gray1,
      },
    },
    [DEVICE_SIZES.XS]: {
      container: { flex: 1, width: '100%', paddingTop: 20, paddingHorizontal: 20 },
      header_label: { marginBottom: 22 },
      v_separator: {
        height: 0.5,
        opacity: 0.3,
        backgroundColor: colors.gray1,
      },
    },
  }
);

export default useStyles;