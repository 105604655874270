import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import TypographyStyle from '@assets/typography';
import { StyleSheet } from 'react-native';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      alignItems: 'center',
      justifycontent: 'center',
      marginTop: 35,
      marginBottom: 24,
    },
    i_notary: { width: 140, height: 80 },
    t_notary_notes: StyleSheet.flatten([
      TypographyStyle.heading4Regular,
      { fontSize: 21, color: colors.white, fontFamily: 'DMSans-Bold' },
    ]),
  },
  {
    [DEVICE_SIZES.MD]: { 
      i_notary: { width: 250 },
      container: {marginTop: 200}
    },
    [DEVICE_SIZES.SM]: {
      i_notary: { width: 200 },
    },
    [DEVICE_SIZES.XS]: {
      i_notary: { width: 130, height: 70 },
      t_notary_notes: {
        fontSize: 20,
      },
    },
  }
);

export default useStyles;
