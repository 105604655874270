import { combineReducers } from '@reduxjs/toolkit';
import auth from './slices/auth.slice';
import signup from './slices/signup.slice';
import forgot from './slices/forgot.slice';
import appstate from './slices/appstate.slice';
import user from './slices/user.slice';
import signsecure from './slices/signsecure.slice';
import dashboard from './slices/dashboard.slice';

const reducer = combineReducers({
  auth,
  signup,
  forgot,
  user,
  appstate,
  signsecure,
  dashboard,
}); // ADD SLICES HERE

export default reducer;
