import React from 'react';
import { FlatList } from 'react-native-gesture-handler';
import DataItem from './data-item';

type DataTableRowProps = {
  data: any[];
  headers: any[];
  renderItem?: any;
  disabled?: boolean;
  onRowPress?: any;
  onPressItem?: any;
  renderMobile?: any;
  ItemSeparatorComponent?: any;
  tableName?: string;
};

const DataTableRow = (props: DataTableRowProps) => {
  const { headers, renderItem, data, renderMobile } = props;

  return (
    <FlatList
      data={data}
      keyExtractor={(item: any, index: number) => `r_index${index}`}
      ItemSeparatorComponent={props.ItemSeparatorComponent}
      renderItem={({ item, index }) => {
        const isfirst = index === 0;
        const islast = index === data.length - 1;

        if (typeof renderItem === 'function') {
          return renderItem({ item, index, islast });
        }

        return (
          <DataItem
            tableName={props.tableName}
            key={`r_index${index}`}
            disabled={props.disabled || Boolean(!props.onRowPress)}
            onPress={() => props?.onRowPress(item)}
            onPressItem={() => props?.onPressItem(item)}
            renderMobile={renderMobile}
            {...{ item, isfirst, islast, headers }}
          />
        );
      }}
    />
  );
};

export default React.memo(DataTableRow);
