import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { StyleSheet } from 'react-native';
import TypographyStyle from '@assets/typography';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      overflow: 'hidden',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.colorPrimary,
    },
    scrollview: { flex: 1, width: '100%' },
    v_body: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
    },
    v2_body: {
      width: '100%',
      height: '100%',
      flexDirection: 'row',
    },
    v_login: {
      width: "50%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      paddingHorizontal: "10%",
      backgroundColor: colors.white,
    },
    v_login_landscape: {
      width: "50%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      paddingHorizontal: "10%",
      backgroundColor: colors.white,
    },
    v2_login: {
      zIndex: 2,
      width: '50%',
      justifyContent: 'center',
      paddingHorizontal: '10%',
      backgroundColor: colors.white,
    },
    signInCenter: { alignItems: 'center', marginTop: 20 },
    txtNote1: StyleSheet.flatten([TypographyStyle.heading2Bold]),
    v_field: { marginTop: 0 },
    txtNote2: StyleSheet.flatten([
      TypographyStyle.heading4Regular,
      { marginTop: 16 },
    ]),
    txtNote3: StyleSheet.flatten([
      TypographyStyle.heading3Bold,
      { textAlign: 'center', fontSize: 30, lineHeight: 33, marginLeft: -70},
    ]),
    t_error: StyleSheet.flatten([
      TypographyStyle.ButtonLRegular,
      { marginTop: 13, color: colors.red, fontSize: 15, textAlign: 'center' },
    ]),
    v_remember_forgot: {
      marginTop: 20,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    v_wrap_remember: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    v_remember_box: {
      width: 18,
      height: 18,
      borderWidth: 1,
      borderRadius: 4,
      alignItems: 'center',
      justifyContent: 'center',
      borderColor: colors.border2,
    },
    v_remember_box_active: {
      borderColor: colors.black1,
      backgroundColor: colors.black1,
    },
    t_remember_me: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        marginLeft: 5,
      },
    ]),
    t_forgot_password: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        fontWeight: '700',
        color: colors.orange1,
      },
    ]),
    t_signup: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        marginTop: 80,
        textAlign: 'center',
        alignSelf: 'center',
        color: colors.black3,
        marginBottom: 20
      },
    ]),
    t_signup_2: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        color: colors.orange1,
        fontWeight: '700',
      },
    ]),

    btn_signin: { marginTop: 30, backgroundColor: colors.black },
    btn_signin2: { marginTop: 30, backgroundColor: colors.purple },
    lbl_signin: {
      fontFamily: 'Montserrat-Bold',
    },
    btn_signin_google: {
      marginTop: 15,
      borderWidth: 1,
      borderColor: colors.border2,
      backgroundColor: colors.white,
      shadowColor: colors.black1,
      shadowOffset: { width: 0, height: 4 },
      shadowOpacity: 0.08,
      shadowRadius: 9,
    },
    lbl_signin_google: StyleSheet.flatten([
      TypographyStyle.googleText,
      {
        color: colors.gray1,
      },
    ]),

    // LOGO COMPONENT
    v_logo_container: {
      padding: 25,
      width: '40%',
      marginLeft: '10%',
      minWidth: 500,
      paddingRight: 268,
      backgroundColor: colors.transparent,
    },
        v2_logo_container: {
        flex: 1,
        width: "50%",
        alignItems: "flex-start",
        justifyContent:"flex-start",
        paddingLeft: 140,
        paddingTop: 160
    },
    //IMAGE BG
    i_bg: {
      ...StyleSheet.absoluteFillObject,
      width: "100%",
      height: "100%",
    },
    copyRightStyle: StyleSheet.flatten([
      TypographyStyle.copyRightText,
      { zIndex: 3, marginBottom: 45, alignSelf: 'center', color: colors.white },
    ]),
    copyRightBtnStyle: StyleSheet.flatten([TypographyStyle.copyRightButton , {color: colors.white}]),

    // BACKGROUND COMPONENT
    linearStyle: {
      position: 'absolute',
      zIndex: -1,
      width: '100%',
      height: '100%',
    },
    itemSeparator: {
      height: 1,
      marginHorizontal: 10,
      backgroundColor: colors.gray4,
      marginTop: 10,
      width: '35%'
    },
    textSeparator: {
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    btn_google: {
      backgroundColor: colors.white,
      borderWidth: 1,
      borderColor: colors.border2,
      width: 50,
      height: 48,
    },
    unawaProductStyle : StyleSheet.flatten([
      TypographyStyle.copyRightText,
      { position: "absolute", bottom: "15%",
        alignSelf: "flex-start",textAlign: "flex-start", 
        color: colors.white },
    ]),
    t_logo: {
      fontFamily: "DMSans",
      fontWeight: "700",
      paddingTop: 0,
      fontSize: 50,  
      color: colors.white, 
    },
    v_height_top_landscape: {height: "25%"},
    v_height_top: { height: "10%" },
    labelInputStyle: {fontSize: 16, marginTop: 20},
    labelInputStyle1: {fontSize: 16},
    separatorContainer: {flexDirection: 'row', flex: 1, marginTop: 50, justifyContent: 'center'},
    labelInput:{}
  },
  {
    [DEVICE_SIZES.XL]: {
      v_logo_container: {
        marginLeft: '10%',
      },
      // itemSeparator: {width: '35%'},
      labelInputStyle: {fontSize: 16, marginTop: 20},
      v_height_top: {height: 90},
      txtNote3: {
        fontSize: 65,
        lineHeight: 60,
        textAlign: 'left'
      },
      txtNote1: StyleSheet.flatten([TypographyStyle.heading2Bold, {
        fontSize: 33,
        lineHeight: 28,
        color: colors.black2,
        marginTop: -50
      }]),
      txtNote2: StyleSheet.flatten([
        TypographyStyle.heading4Regular,
        { marginTop: 16, fontSize: 18, lineHeight: 32, color: colors.black2 },
      ]),
      t_remember_me: StyleSheet.flatten([
        TypographyStyle.fieldsTitle,
        {
          fontSize: 16,
          marginTop: 50
        },
      ]),
      t_forgot_password: StyleSheet.flatten([
        TypographyStyle.fieldsTitle,
        {
          fontWeight: '700',
          color: colors.blue,
          fontSize: 16,
          marginTop: 50
        },
      ]),
      v_remember_box: {marginTop: 50},
      btn_signin2: { marginTop: 30, backgroundColor: colors.purple },
      t_signup_2: StyleSheet.flatten([
        TypographyStyle.fieldsTitle,
        {
          color: colors.purple,
          fontWeight: '700',
        },
      ]),   
    },
    [DEVICE_SIZES.LG]: {
      v_logo_container: {
        padding: 25,
        width: '36%',
        marginLeft: '10%',
        minWidth: 300,
        paddingRight: 110,
        backgroundColor: colors.transparent,
      },
      v_remember_forgot: {
        marginTop: 30,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      labelInput: {marginBottom: 20},
      itemSeparator: { width: '30%'},
      separatorContainer: {marginTop: 20},
      btn_signin: { marginTop: 15, backgroundColor: colors.black, height: 40 },
      btn_signin2: { marginTop: 15, backgroundColor: colors.purple, height: 40 },
      labelInputStyle: {fontSize: 16, marginTop: 0},
      unawaProductStyle : StyleSheet.flatten([
        TypographyStyle.copyRightText,
        { position: "relative", bottom: 0, top: 150,
          alignSelf: "center",textAlign: "left", 
          color: colors.white },
      ]),
      v_height_top: {height: 145},
      v_height_top_landscape: {height: '15%'},
      t_logo: { fontSize: 28.75, lineHeight: 30, marginTop: 5 },
      txtNote3: {
        fontSize: 35,
        lineHeight: 60,
        textAlign: 'left'
      },
      v_body: {
        width: '100%',
      },
      container: {
        flex: 1,
        transform: [{ scaleX: 1.1 }],
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.colorPrimary,
      },
      t_error: { fontSize: 13, lineHeight: 20 },
      txtNote1: {
        fontSize: 24,
        lineHeight: 25,
      },
      txtNote2: {
        marginTop: 4,
        fontSize: 16,
        lineHeight: 20,
      },
    },
    [DEVICE_SIZES.MD]: {
      i_bg: {
        transform: [{ scaleX: 1.2 }],
      },
      // itemSeparator: {width: 90},
      v_height_top_landscape: {height: 50},
      v_height_top: { height: 51 },
      v_login_landscape: {
        width: "75%",
        height: "75%",
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: "0%",
        marginRight: 'auto',
        marginLeft: 'auto',
        backgroundColor: colors.white,
      },
      itemSeparator: {width: '28%'},
      scrollview: {
        width: "50%"
      },
      v2_logo_container: {
        // width: "20%"
        display: 'none'
      },
      t_logo: { 
        fontSize: 32, 
        lineHeight: 41, 
        marginTop: 15, 
        paddingBottom: 90, 
        fontWeight: "500",
        textAlign: "center"
      },
      unawaProductStyle : StyleSheet.flatten([
        TypographyStyle.copyRightText,
        { position: "relative", bottom: 0, top: 50,
          alignSelf: "center",textAlign: "center", 
          color: colors.black },
      ]),
      txtNote3: {
        fontSize: 35,
        lineHeight: 60,
        textAlign: 'left'
      },
      v_login: {
        marginBottom: 20,
        marginTop: 200
      },
      txtNote1: {
        fontSize: 40
      },
      txtNote2: {
        fontSize: 20
      },
      t_forgot_password: { marginTop: 20 },
      v_remember_box: { marginTop: 20 },
      t_remember_me: StyleSheet.flatten([
        TypographyStyle.fieldsTitle,
        {
          marginTop: 20
        },
      ]),
    },
    [DEVICE_SIZES.SM]: {
      i_bg: {
        transform: [{ scaleX: 1 }],
      },
      lbl_signin: {fontSize: 13},
      v_height_top_landscape: {height: 50},
      v_height_top: { height: 51 },
      v_login_landscape: {
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: "0%",
        backgroundColor: colors.white,
      },
      scrollview: {
        width: "60%"
      },
      v2_logo_container: {
        // width: "20%"
        display: 'none'
      },
      unawaProductStyle : StyleSheet.flatten([
        TypographyStyle.copyRightText,
        { position: "relative", bottom: 0, top: 50,
          alignSelf: "center",textAlign: "center", 
          color: colors.black },
      ]),
      t_logo: { 
        fontSize: 16, 
        lineHeight: 25, 
        marginTop: 15, 
        paddingBottom: 80, 
        fontWeight: "500",
        textAlign: "center"
      },
      itemSeparator: {width: '28%'},
      v_body: {
        width: '80%',
        alignItems: 'flex-start',
        marginHorizontal: 20,
        marginBottom: 40,
        flexDirection: 'row'
      },
      v_login: {
        minWidth: '100%',
        maxWidth: '100%',
        paddingHorizontal: 80,
      },
      t_error: { fontSize: 13, lineHeight: 20 },
      txtNote1: {
        fontSize: 22,
        lineHeight: 25,
      },
      txtNote2: {
        marginTop: 4,
        fontSize: 16,
        lineHeight: 20,
      },
      copyRightStyle: {
        fontSize: 11,
      },
      copyRightBtnStyle: {
        fontSize: 11,
      },
      t_forgot_password: { marginTop: 20 },
      v_remember_box: { marginTop: 20 },
      t_remember_me: StyleSheet.flatten([
        TypographyStyle.fieldsTitle,
        {
          marginTop: 20
        },
      ]),
    },
    [DEVICE_SIZES.XS]: {
      v_height_top_landscape: {height: 145},
      v_height_top: { height: 51 },
      v_login_landscape: {
        width: "50%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: "10%",
        backgroundColor: colors.white,
      },
      scrollview: {
        width: "100%"
      },
      v_remember_forgot: {
        marginTop: 20,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      v2_logo_container: {
        // width: "20%"
        display: 'none'
      },
      itemSeparator: {width: '22%'},
      t_logo: { 
        fontSize: 20, 
        lineHeight: 25, 
        marginTop: 15, 
        paddingBottom: 90, 
        fontWeight: "500",
        textAlign: "center"
      },
      unawaProductStyle : StyleSheet.flatten([
        TypographyStyle.copyRightText,
        { position: "relative", bottom: 0, top: 50,
          alignSelf: "center",textAlign: "center", 
          color: colors.black },
      ]),
      i_bg: {
        transform: [{ scaleX: 1 }],
      },
      v_body: {
        width: '90%',
        alignItems: 'flex-start',
        marginHorizontal: 20,
        marginBottom: 40,
      },
      v_login: {
        minWidth: '100%',
        maxWidth: '100%',
        paddingHorizontal: 20,
      },
      t_error: { fontSize: 13, lineHeight: 20 },
      txtNote1: {
        fontSize: 20,
        lineHeight: 25,
      },
      txtNote2: {
        marginTop: 4,
        fontSize: 14,
        lineHeight: 20,
      },
      lbl_signin: {fontSize: 13},
      t_forgot_password: { marginTop: 20 },
      v_remember_box: { marginTop: 20 },
      t_remember_me: StyleSheet.flatten([
        TypographyStyle.fieldsTitle,
        {
          marginTop: 20
        },
      ]),
      copyRightStyle: {
        color: colors.white,
        fontSize: 11,
      },
      copyRightBtnStyle: {
        fontSize: 11,
        color: colors.red
      },
    },
  }
);

export default useStyles;
