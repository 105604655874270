import React from 'react';
import { TouchableOpacity, View, ScrollView } from 'react-native';
import Icon from '@expo/vector-icons/Feather';
import useStyles from './styles.css';
import colors from '@assets/colors';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Notification from '@src/screens/drawer-screens/notification';
import { DrawerContext } from '@src/screens/drawer-screens/DrawerProvider';
import { useAppSelector } from '@src/ducks/ducksHook';
import { selectedAuthRole } from '@src/ducks/slices/auth.slice';

const NotificationButton = () => {
  const styles = useStyles();
  // const [visible, setVisible] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const role = useAppSelector(selectedAuthRole);
  return (
    <>
      <TouchableOpacity onPress={handleOpen}>
        <Icon name="bell" size={24} color={colors.black} />
        <View style={styles.icon_notif} />
      </TouchableOpacity>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={role !== 'principal' ? styles.modalPrincipal : styles.modalNotary}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Notifications
          </Typography>
          <View style={styles.container}>
            <ScrollView>
              <Notification />
              <View style={styles.v_height} />
            </ScrollView>
          </View>
        </Box>
      </Modal>
    </>
  );
};

export default NotificationButton;
