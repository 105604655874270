/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useContext } from 'react';
import DataTableRow from '@src/components/data-table/data-row';
import { Text, View, ScrollView } from 'react-native';
import useStyles from './styles';
import { desktopSize } from "@src/utils/screensize-helper";
import { DrawerContext } from '@src/screens/drawer-screens/DrawerProvider';

const Notification = () => {
  const styles = useStyles();
  const [order, setOrder] = useState<any>(undefined);
  const [orderBy, setOrderBy] = useState('name');
  const isDesktop = desktopSize()
  const { onActiveTab } = useContext(DrawerContext);

  React.useEffect(() => {
    if (isDesktop) {
      onActiveTab('Dashboard')
    }
  }, [isDesktop]);

  const filteredUsers = [
    {
      label: 'Today',
      body: [
        {
          "name": "DMS Notification System",
          "datetime": "1m ago",
          "subject": "Demo Document Access",
          "status": "Unread",
          "actions": "View",
          "highlighted": ["Atty. Juan Martin"],
          "message": "Your session with Atty. Juan Martin is about to start."
        },
        {
          "name": "DMS Notification System",
          "datetime": "1h ago",
          "subject": "Document for Approval",
          "status": "Unread",
          "actions": "View",
          "highlighted": [],
          "message": "Jane Dela Cruz approved the document."
        },
      ]
    }, {
      label: 'This week',
      body: [
        {
          "name": "DMS Notification System",
          "datetime": "1d ago",
          "subject": "Document for Signing  ",
          "status": "Unread",
          "actions": "View",
          "highlighted": ["Session Delay", "Attorney Carla Ong"],
          "message": "Session Delay: We regret to inform you that there is a slight delay in starting your session with Attorney Carla Ong."
        },
        {
          "name": "DMS Notification System",
          "datetime": "3d ago",
          "subject": "Document for Signing",
          "status": "Viewed",
          "actions": "View",
          "highlighted": [],
          "message": "Calvin approved the document."
        },
        {
          "name": "DMS Notification System",
          "datetime": "3d ago",
          "subject": "Document for Signing",
          "status": "Viewed",
          "actions": "View",
          "highlighted": ["Rescheduling", "Attorney Travis Tiu"],
          "message": "Rescheduling: Your session with Attorney Travis Tiu has been rescheduled."
        },
        {
          "name": "DMS Notification System",
          "datetime": "4d ago",
          "subject": "Document for Signing",
          "status": "Viewed",
          "actions": "View",
          "highlighted": ["Attorney Juan Martin"],
          "message": "Your session with Attorney Juan Martin has concluded."
        }
      ]
    }
  ]

  const renderTextWith = (text: string, highlight: string[]) => {
    if (!highlight || highlight.length === 0) {
      return <Text style={styles.inbox_text}>{text}</Text>;
    }
    const parts = text.split(new RegExp(`(${highlight.join('|')})`, 'gi'));
    return (
      <Text style={styles.inbox_text}>
        {parts.map((part, i) => (
          <Text
            key={i}
            style={
              highlight.includes(part)
                ? { fontWeight: 'bold' }
                : {}
            }
          >
            {part}
          </Text>
        ))}
      </Text>
    );
  }

  return (
    <View style={styles.container}>
      <ScrollView>
        <>
          <DataTableRow
            tableName="Signature"
            headers={[]}
            data={filteredUsers}
            renderItem={(props: any) => (
              <>
                <Text style={styles.header_label}>{props.item.label}</Text>
                <DataTableRow
                  tableName="Signature"
                  headers={[]}
                  data={props.item.body}
                  ItemSeparatorComponent={() => (
                    <View style={styles.v_separator} />
                  )}
                  renderItem={(props: any) => (
                    <View style={styles.inbox_row}>
                      <View style={props.item.status === "Unread" ? styles.inbox_status : styles.inbox_status_viewed} />
                      <View style={styles.inbox_content}>
                        {renderTextWith(props.item.message, props.item.highlighted)}
                        <Text style={styles.inbox_text}>{props.item.datetime}</Text>
                      </View>
                    </View>
                  )}
                />
              </>
            )}
          />
        </>
        <View style={styles.v_height} />
      </ScrollView>
    </View>
  );
};

export default Notification;
