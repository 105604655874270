import colors from "@assets/colors";
import Icon from "@expo/vector-icons/MaterialIcons";
import { TextInput01 } from "@src/components/textinput";
import React, {useContext} from "react";
import { DrawerContext } from "../../../DrawerProvider";
import useStyles from "./styles.css";
import { View, Text } from 'react-native';

const SearchHeader = () => {
  const styles = useStyles();
  const { search, onSearchStateChange } = React.useContext(DrawerContext);

  return (
    
    <View>
      <TextInput01
      value={search}
      placeholder={"Search"}
      inputStyle={styles.inputStyle}
      onChangeText={onSearchStateChange}
      containerStyle={styles.input_search}
      leftIcon={() => <Icon name="search" size={19} color={colors.grayPrimary} />} editable={true}    /> 
    </View>
  );
};

export default SearchHeader;
