import React from "react";
import { ImageBackground } from "react-native";
import { LinearGradient } from 'expo-linear-gradient';
import useOrientationHelper from "@src/utils/orientation-helper";
import { mobileSize } from "@src/utils/screensize-helper";
import images from "@assets/images";
import useStyles from './styles.css';

const Background = () => {
  const styles = useStyles();
  const isMobile = mobileSize();
  const isLandScape = useOrientationHelper();

  return (
      <>
        <LinearGradient
          colors={["#253A60", "#253A60", "#4E70BB", "#4E70BB"]}
          style={[isLandScape ? styles.linearStyle : styles.linearStyle_portrait]}
        />
        <ImageBackground
          resizeMode="stretch"
          source={images.v2_bg_mask}
          style={[styles.i_bg]}
        />
      </>
  );
};

export default Background;
