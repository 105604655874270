import React, { useState, useEffect } from 'react';
import { NavigationScreenProps } from '@src/navigators/NavigatorTypes';
import { useNavigation } from '@react-navigation/native';
import { TextInput02 } from '@src/components/textinput';
import { useForgotService } from '@src/ducks/hooks';
import { Button01 } from '@src/components/button';
import { Button02 } from '@src/components/button';
import { Text, View } from 'react-native';
import useStyles from './styles.css';
import ErrorView from './error';
import { mobileSizeChecker } from '@src/utils/screensize-helper';
import useOrientationHelper from "@src/utils/orientation-helper";
import { ScrollView } from "react-native-gesture-handler";
import _ from 'lodash';

interface ErrorProps {
  email: string;
  password: string;
}

let ErrorValue = {
  email: '',
  password: '',
};

const ForgotEmail = () => {
  const styles = useStyles();
  const errorRef = React.useRef<any>(null);
  const { isLoading, onForgot } = useForgotService();
  const [email, setUsername] = useState<string>('');
  const [error, setError] = useState<ErrorProps>(ErrorValue);
  const navigation = useNavigation<NavigationScreenProps<'Home'>>();
  const isMobile = mobileSizeChecker();
  const isLandScape = useOrientationHelper();

  const onForgotPass = () => {
    let newError: any = {};
    // if (!email) {
    //   newError.email = 'Email is required.';
    // }
    if (!email) {
      newError.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      newError.email = 'Invalid email address'
    }

    setError(newError);

    if (_.isEmpty(newError)) {
      onForgot({ email });
    }
  };


  const onCancelPass = () => {
  }

  const onSignUp = () => {
    navigation.navigate('Signup');
  };

  useEffect(() => {
    setError({} as ErrorProps);
    errorRef.current?.onClear();
  }, [email]);

  return (
    <View style={isLandScape ? styles.v_login_landscape : styles.v_login}>    
    <ScrollView showsVerticalScrollIndicator={false} 
        style={styles.scrollview}>   
      <View style={isLandScape ? styles.v_height_top_landscape : styles.v_height_top} />
      <View style={styles.signInCenter}>
        <Text style={styles.txtNote1}>Reset Password</Text>
        <Text style={styles.txtNote2}>
          {`Enter your email below and we'll send you instructions \n on how to reset your password`}
        </Text>
      </View>
      
      <View style={styles.v_field}>
        <TextInput02
          value={email}
          label="Email Address"
          error={error.email}
          onChangeText={setUsername}
          placeholder="Enter your email address here"
          labelStyle={styles.emailText}
        />
        <View style={{paddingHorizontal: 100}}>
        <ErrorView ref={errorRef} />
        </View>
        <Button01
          label="Reset Password"
          color={'white'}
          onPress={onForgotPass}
          isLoading={isLoading}
          style={styles.btn_signin}
          labelStyle={styles.lbl_signin}
        />
        
        {/*************HIDE CANCEL BUTTON
          <Button01
          label="Cancel"
          color={'white'}
          onPress={() => navigation.navigate('Signin')}
          isLoading={isLoading}
          style={styles.btn_cancel}
          labelStyle={styles.lbl_signin}
        /> */}
        <Text style={styles.t_signup}>
          Don’t have an account yet?
          <Text style={styles.t_signup_2} onPress={onSignUp}>
            {' '}
            Register here
          </Text>
        </Text>
      </View>
      </ScrollView>
    </View>
  );
};

export default ForgotEmail;
