import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { View, Text, ScrollView } from 'react-native';
import { Button01 } from '@src/components/button';
import useStyles from './styles.css';
import { colors } from 'theme';

const PrivacyPolicyBody = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const btnLabel = 'Go to dashboard';

  const onPress = () => {
    navigation.navigate('Home');
  };

  return (
    <View style={styles.v_body}>
        <ScrollView>
          
          <Text style={[styles.t_note, { marginTop: 10 }]}>
          We at UNAWA are committed to protecting and respecting your privacy by 
          collecting and processing your personal information and sensitive <br/> personal 
          information in accordance with applicable data privacy laws and regulations 
          (“Data Privacy Laws”), including the Philippine Data Privacy <br/> Act of 2012 (“DPA”) 
          and its implementing rules and regulations (“DPA IRR”) (collectively, the “Privacy Laws”).
          </Text>
          <Text style={[styles.t_note, { marginTop: 15 }]}>
          This Privacy Notice sets out the manner by which UNAWA collects and processes your personal data.<br/>
          This Privacy Notice has been prepared and used as of January 2020 (“Notice Date”). We reserve the 
          right to amend this Privacy Notice from time to time to reflect changing legal requirements or 
          our processing practices. Any such changes will be posted on this website and will be effective upon posting.
          </Text>
          <Text style={[styles.t_note, { marginTop: 15 }]}>
            The personal information we collect and process
          </Text>
          <Text style={[styles.t_note, { marginTop: 15 }]}>
            As of the Notice Date, the personal information we collect and
            process may include:
          </Text>
          <Text style={[styles.t_note, { marginTop: 15 }]}>
          1. Information that will allow us to contact you, such as your name, address, email address, and telephone and mobile numbers;<br/>
          2. Information that will allow us to verify your identity, such as government-issued identification number and details, e.g. social security numbers;<br/>  tax identification numbers,and passport details; and<br/>
          3. Other personal information that will be necessary to assist us in providing our services to you.
          </Text>
          <Text style={[styles.t_note, { marginTop: 15, color: colors.purple4, fontWeight: '700' }]}>
            How we collect personal information
          </Text>
          <Text style={[styles.t_note, { marginTop: 15 }]}>
            In order to provide our services to you, we will collect personal
            data directly from you or your organization when you or your
            organization:
          </Text>
          <Text style={[styles.t_note]}>• Register to use our services;</Text>
          <Text style={[styles.t_note]}>
          • Engage in the use of our services;
          </Text>
          <Text style={[styles.t_note]}>
          • Access, browse, visit, use, or contact us through our website and
            other online or social media platform;
          </Text>
          <Text style={[styles.t_note]}>
          • Contact us or provide such personal information to us in other
            circumstances.
          </Text>
          {
          /* Hide Go to dashboard Button 
          <Button01
            onPress={onPress}
            style={styles.btn_dashboard}
            label={btnLabel}
          />
          */
          }
        </ScrollView>
      </View>
  );
};

export default PrivacyPolicyBody;
