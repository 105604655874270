/* eslint-disable no-useless-escape */
import { PDFDocument, PageSizes } from 'pdf-lib';
import _ from 'lodash';

export const dashboardDocuments = (array: any[]) => {
  return array.map((item: any) => {
    const auditTrail = item?.workflow?.auditTrail ?? [];
    const recent = auditTrail[auditTrail.length - 1];
    item.recentAction = recent?.details;
    item.status = item?.workflow?.status ?? '';
    return item;
  });
};

// DESCEND AUDITTRAIL
export const auditTrailDescending = (array: any[]) => {
  return _.chain(array).cloneDeep().reverse().value();
};

// IMAGE TO PDF
const convertImageToPDF = async (file: any) => {
  const pdfDoc = await PDFDocument.create();

  let image;
  if (/jpe?g/i.test(file.mimeType)) image = await pdfDoc.embedJpg(file.uri);
  else if (/png/i.test(file.mimeType)) image = await pdfDoc.embedPng(file.uri);
  else throw Error('Please choose a JPEG or PNG file to proceed');

  const page = pdfDoc.addPage(PageSizes.Letter);
  const { width, height } = page.getSize(); // Get the width & height of the page

  const scaled = image.scaleToFit(width, height);

  page.drawImage(image, {
    width: scaled.width,
    height: scaled.height,
    x: (width - scaled.width) / 2,
    y: (height - scaled.height) / 2,
  });

  const b64Chunk = await pdfDoc.saveAsBase64();
  return 'data:application/pdf;base64,' + b64Chunk;
};

// CHOOSE FILE TO READABLE FILE
export const chooseFiles = (item: any) =>
  new Promise((resolve, reject) => {
    if (item.mimeType !== 'application/pdf') {
      convertImageToPDF(item)
        .then((res: any) => {
          resolve({
            size: item.size,
            file: item.file,
            name: item.name.replace(/\.[^\/.]+$/, ''),
            mimeType: 'application/pdf',
            lastModified: item.lastModified,
            uri: res,
          });
        })
        .catch(reject);
    } else {
      resolve({
        size: item.size,
        name: item.name.replace(/\.[^\/.]+$/, ''),
        file: item.file,
        mimeType: item.mimeType,
        lastModified: item.lastModified,
        uri: item.uri,
      });
    }
  });

// FILE TO READABLE FILE
export const dragFiles = (item: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(item);
    reader.onload = async (e: any) => {
      const params = {
        size: item.size,
        name: item.name.replace(/\.[^\/.]+$/, ''),
        file: item,
        mimeType: item.type,
        lastModified: item.lastModified,
        lastModifiedDate: item.lastModifiedDate,
        uri: e.target.result,
      };

      if (params.mimeType !== 'application/pdf') {
        const result = await convertImageToPDF(params);
        params.uri = result;
        params.mimeType = 'application/pdf';
      }

      resolve(params);
    };
    reader.onerror = reject;
  });

export const convertFileListToArray = (fileList: any) => {
  const fileArray = [];

  for (const key in fileList) {
    if (fileList.hasOwnProperty(key)) {
      const file = fileList[key];
      fileArray.push(file);
    }
  }

  return fileArray;
};

// CONVERT ARRAY INTO PAGINATION
export const paginateArray = (array: any[], itemsPerPage: number) => {
  const pages = [];
  for (let i = 0; i < array.length; i += itemsPerPage) {
    pages.push(array.slice(i, i + itemsPerPage));
  }
  return pages;
};