import { CreateResponsiveStyle, DEVICE_SIZES, maxSize, minSize } from 'rn-responsive-styles';
import TypographyStyle from '@assets/typography';
import { StyleSheet } from 'react-native';
import colors from '@assets/colors';

const useStyles = CreateResponsiveStyle(
  {
    // INDEX
    v_signup: {
      zIndex: 2,
      width: '50%',
      justifyContent: 'center',
      paddingHorizontal: '8%',
      backgroundColor: colors.white,
      alignItems: 'center'
    },
    v_logo_container: {
      width: '45%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    signInCenter: { textAlign: 'left', marginTop: 30 },
    txtNote1: StyleSheet.flatten([
      TypographyStyle.heading2Bold,
      { textAlign: 'left' },
    ]),
    txtNote2: StyleSheet.flatten([
      TypographyStyle.heading4Regular,
      { fontSize: 20, marginTop: 16, textAlign: 'center' },
    ]),
    v_field: { marginTop: 50 },
    v_flexdirection: { flexDirection: 'row' },
    v_cardseparator: { width: 30, height: 30 },
    t_signup: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        marginTop: 35,
        textAlign: 'left',
        color: colors.black3,
      },
    ]),
    t_terms: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        color: colors.orange1,
        fontWeight: '700',
      },
    ]),

    // CARD
    cr_modal_container: {
      position: 'absolute',
      flex: 1,
      width: '100%',
      height: '100%',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.blurBG,
    },
    cr_field_v: {
      width: 780,
      height: 477,
      backgroundColor: colors.white,
      paddingHorizontal: 64,
      paddingTop: 60,
      paddingBottom: 48,
      borderRadius: 10,
    },
    cr_header: StyleSheet.flatten([TypographyStyle.heading4Bold]),
    cr_card_v: {
      marginVertical: 52,
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    cr_btn_cancel: {
      backgroundColor: colors.white,
      borderColor: colors.purple,
      borderWidth: 1,
      width: '30%',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
    },
    cr_btn_label: StyleSheet.flatten([
      TypographyStyle.buttonText,
      { color: colors.purple },
    ]),
    cr_card_container: {
      flex: 1,
      paddingTop: 39,
      borderRadius: 6,
      borderWidth: 1,
      borderColor: colors.border2,
    },
    cr_card_text_v: {
      flex: 1,
      paddingRight: 15,
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
    },
    cr_card_icon: { marginLeft: 29, marginRight: 11 },
    cr_flex1: { flex: 1 },
    cr_card_title: {
      fontSize: 20,
      lineHeight: 24,
      color: colors.black3,
      fontFamily: 'DMSans-Medium',
    },
    cr_i_next: { marginTop: 8, marginLeft: 6 },
    cr_card_desc: {
      fontFamily: 'DMSans',
      fontWeight: '400',
      lineHeight: 24,
      fontSize: 13,
      color: colors.lightBlack,
    },
    cr_card_btn: {
      flex: 1,
      marginTop: 25,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 32,
      paddingRight: 10,
      paddingVertical: 5,
      backgroundColor: colors.lilac,
    },
    cr_card_btn_label: StyleSheet.flatten([
      TypographyStyle.buttonText,
      { fontFamily: 'DMSans' },
    ]),
  },
  {
    [DEVICE_SIZES.LG]: {
      txtNote1: {
        fontSize: 44,
        lineHeight: 52,
      },
      txtNote2: {
        fontSize: 24,
        lineHeight: 28,
      },
      cr_card_title: { fontSize: 15, lineHeight: 24 },
      cr_card_btn_label: { fontSize: 14, lineHeight: 16 },
      t_signup: { fontSize: 14, lineHeight: 16 },
    },
    [DEVICE_SIZES.MD]: {
      v_signup: { paddingHorizontal: '4%' },
      cr_card_title: {
        fontSize: 16,
        lineHeight: 24,
        color: colors.black3,
        fontFamily: 'DMSans-Medium',
      },
    },
    [DEVICE_SIZES.SM]: {
      v_signup: {
        borderRadius: 8,
        minWidth: '100%',
        maxWidth: '100%',
        paddingVertical: 50,
        paddingHorizontal: 30,
        marginTop: 100,
        marginBottom: 100
      },
      txtNote1: {
        textAlign: 'center',
        fontSize: 22,
        lineHeight: 25,
      },
      txtNote2: {
        textAlign: 'center',
        marginTop: 4,
        fontSize: 16,
        lineHeight: 20,
      },
      cr_card_title: { fontSize: 18 },
      v_flexdirection: {
        flexDirection: 'column',
      },
      t_signup: { textAlign: 'center' },
    },
    [DEVICE_SIZES.XS]: {
      v_signup: {
        borderRadius: 8,
        minWidth: '100%',
        maxWidth: '100%',
        // paddingVertical: 50,
        paddingBottom: 50,
        paddingHorizontal: 20,
        marginTop: 100,
        marginBottom: 100
      },
      txtNote1: {
        alignItems: 'center',
        textAlign: 'center',
        fontSize: 20,
        lineHeight: 25,
      },
      txtNote2: {
        marginTop: 4,
        fontSize: 14,
        lineHeight: 20,
        textAlign: 'center',
      },
      cr_card_title: { fontSize: 18 },
      v_flexdirection: {
        flexDirection: 'column',
      },
      t_signup: { textAlign: 'center' },
    },
    [minSize(DEVICE_SIZES.XL)]: {
      cr_card_title: { fontSize: 20 },
      t_signup: {textAlign:'center', marginTop: 150},
      t_terms: {color: colors.purple4},
      txtNote1: {fontSize: 36, lineHeight: 52},
      cr_card_btn_label: {fontSize: 14, fontWeight: '400', lineHeight: 16}
    },
  }
);

export default useStyles;
