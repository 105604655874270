import React from "react";
import File from "./file.svg";
import Folder from "./folder.svg";
import Eye from "./eye.svg";
import Chat from "./chat.svg";
import Cinema from "./cinema.svg";
import Calendar from "./calendar.svg";
import { IconProps } from "@src/components/icon/interface";

const SVGDashboard = (props: IconProps) => {
  const { name, width, height } = props;
  switch (name) {
  case "file":
    return <File width={width || 22} height={height || 29} {...props} />;
  case "folder":
    return <Folder width={width || 22} height={height || 29} {...props} />;
  case "eye":
    return <Eye width={width || 20} height={height || 14} {...props} />;
  case "chat":
    return <Chat width={width || 50} height={height || 30} {...props} />;
  case "cinema":
    return <Cinema width={width || 20} height={height || 14} {...props} />;
  case "calendar":
    return <Calendar width={width || 20} height={height || 14} {...props} />;
  default:
    return null;
  }
};

export default React.memo(SVGDashboard);
