import { useCallback } from 'react';
import { useAppDispatch } from '../ducksHook';
import {
  userActions
} from '../slices/user.slice';

// Types

type Operators = {
  onUpdateUserDetails: (params: any) => void;
  fetUserDetails: () => void;
  resetError: () => void;
};

export const useUserService = (): Readonly<Operators> => {
  const dispatch = useAppDispatch();

  return {
    onUpdateUserDetails: useCallback((params: any) => {
      dispatch(userActions.updateUserSuccess(params));
    }, [dispatch]),
    fetUserDetails: useCallback(() => {
      dispatch(userActions.fetchUserDetails());
    }, [dispatch]),
    resetError: useCallback(() => {
      dispatch(userActions.resetError());
    }, [dispatch]),
  };
};

export default useUserService;
