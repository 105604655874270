import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  maxSize,
} from 'rn-responsive-styles';
import TypographyStyle from '@assets/typography';
import { StyleSheet } from 'react-native';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle({
    tooltip: {
        position: "absolute",
        left: "100%",
        top: "-150%",
        textAlign: "left",
        lineHeight: 20,
        backgroundColor: colors.purple5,
        color: "black",
        padding: 20,
        borderRadius: 8,
        width: 220,
        height: 115,
        fontFamily: 'DMSans',
        fontWeight: '400',
        fontSize: 12,
      },
});

export default useStyles;
