import { mobileSizeChecker, tabletSize } from '@src/utils/screensize-helper';
import React from 'react';
import { View } from 'react-native';
import { DrawerContext } from '../../DrawerProvider';
import MobileHeader from './mobile';
import useStyles from './styles.css';
import WebHeader from "./web-view";

const DrawerItemHeader = () => {
  const styles = useStyles();
  const isTablet = tabletSize();
  const isMobile = mobileSizeChecker();
  const { activeTab } = React.useContext(DrawerContext);

  if(["ContactLessRequest"].includes(activeTab)) return null;

  return (
    <View style={styles.drawer_item_header}>
      {isMobile || isTablet ? <MobileHeader /> : <WebHeader />}
    </View>
  );
};

export default DrawerItemHeader;
