import _ from 'lodash';
import { useCallback, useState } from 'react';

export const useValidation = () => {
  const [error, setError] = useState<any>({});

  const checkValidation = useCallback(
    (requiredFields: any, stateInput: any) => {
      const newError: any = {};
      const ArrayConvert = _.isArray(requiredFields)
        ? requiredFields
        : Object.keys(requiredFields);
      ArrayConvert.forEach((field: any) => {
        if (!stateInput[field]) {
          newError[field] = 'This field is required';
        }
      });

      if (!_.isEmpty(newError)) {
        setError(newError);
      }

      return newError;
    },
    []
  );

  const onSetError = useCallback(
    (newError: any) => {
      setError(newError);
    },
    [error]
  );

  return { errorInput: error, checkValidation, onSetError };
};

// CHECK PARTIES HAS DUPLICATE EMAILS
export const hasDuplicateEmails = (array: any[], disableRef: boolean = false) => {
  const emailSet = new Set();

  for (const obj of array) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty("email") && obj.email) {
      if (emailSet.has(obj.email)) {
        !disableRef && obj?.ref?.current?.onError({ email: "Duplicate email" });
        return true; // Duplicate email found
      } else {
        emailSet.add(obj.email);
      }
    }
  }

  return false; // No duplicate emails found
};

// DISABLE AFFIX BUTTON IF THERE's ITEM NEEDED TO BE SIGNED
export const disableAffixButton = (document: any = {}, user: any = {}, activeIndex: number) => {
  const signatures = document?.workflow?.signatures ?? [];

  const count = signatures.reduce((result: number, value: any) => {
    value.forEach((item: any) => {
      if(["signature", "initials", "signature-name", "signature-notary", "signature-date", "signature-name-designation"].includes(item.type) 
      && !item.signature && item.trackId === user.trackId){
        result += 1;
      }
    });

    return result;
  },0);

  return count > 0 && activeIndex === 5;
};

// CHECKING AFFIX SIGNATURE
export const checkEnableSignature = (document: any, email: string) => {
  const parties = document?.parties ?? [];

  const currentSigner = parties.find((item: any) => item.email === email) || {};
    if(currentSigner.status !== "SIGNED"){
      return true;
    }

  return false;
};

// DISABLE AFFIX BUTTON IF THERE's ITEM NEEDED TO BE SIGNED
export const countSignatures = (signatures: any = []) => {
  const result = signatures.reduce((result: { total: number, count: number, checkCount: number}, value: any) => {
    value.layerFields.forEach((item: any) => {
      if(["signature", "initials", "signature-name", "signature-date", 
        "signature-name-designation"].includes(item.type) && !item.signature){
        result.count += 1;
      }else if(item.type === "textbox" && !item.text){
        result.count += 1;
      }

      if(["signature", "initials", "signature-name", "signature-date", 
        "signature-name-designation", "textbox"].includes(item.type)){
        result.total += 1;
      }

      if(item.type === "checkbox" && !result.checkCount){
        result.total += 1;
        result.checkCount = 1;
      }
    });

    if(value.layerFields.find((item: any) => item.type === "checkbox" && item.isChecked)){
      result.checkCount = 0;
    }

    return result;
  },{total: 0, count: 0, checkCount: 0});

  return { total: result.total, count: result.count + result.checkCount};
};

export const isToday = (date: string | Date): boolean => {
  const givenDate = new Date(date);
  const currentDate = new Date();

  return (
    givenDate.getFullYear() === currentDate.getFullYear() &&
    givenDate.getMonth() === currentDate.getMonth() &&
    givenDate.getDate() === currentDate.getDate()
  );
};