import React, { useContext, useRef, useState } from 'react';
import { View, ScrollView, useWindowDimensions, TouchableOpacity, Text } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
// import SigningModal from './drawer-component/SigningModal';
import CreateRequestModal from './create-request-modal';
import DrawerComponent from './drawer-component';
import DrawerHeaderBody from './drawer-body';
import useStyles from './styles';
import DrawerMask from "./drawer-component/drawer-mask";
import SVGDashboard from "@assets/svg/dashboard";
import Support from "./support/index";
import { tabletSize, mobileSize} from "@src/utils/screensize-helper";
import { DrawerContext } from "../drawer-screens/DrawerProvider";
import Animated, { Extrapolation, interpolate, runOnJS, useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";
import { LinearGradient } from 'expo-linear-gradient';

const HomeScreen = () => {
  const styles = useStyles();
  const { width, height } = useWindowDimensions();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const chatboxRef = useRef<HTMLDivElement>(null);
  const isTabletSize = tabletSize();
  const isMobile = mobileSize();
  const { onActiveTab } = useContext(DrawerContext);
  const animated = useSharedValue(0);

  const toggleSupportChat = () => {
    if(isMobile) {
      onActiveTab("Support");
      setIsVisible(false);
    } else {
      if(isVisible){
        animated.value = withTiming(0, { duration: 300 },
          (finished) => {
            if (finished) runOnJS(setIsVisible)(!isVisible);
          });
      }else{
        setIsVisible(!isVisible);
        animated.value = withTiming(1, { duration: 300 });
      }
    }
  };

  return (
    <View style={styles.container}>
      <SafeAreaView />
      <LinearGradient
        colors={["#FFFFFF", "#F3F4FE"]}
        locations={[0.4, 1]}
        style={[styles.linear_style]}
      />
      <DrawerComponent />
      <ScrollView style={[styles.scrollView, { width, height }]}>
        <DrawerHeaderBody />
        <DrawerMask />
      </ScrollView>
      <CreateRequestModal />
      {/* <SigningModal /> */}
      <View style={[styles.chatSupportBtn]} >
        <TouchableOpacity style={styles.chatButton} onPress={toggleSupportChat} >
          <View style={styles.contentButton}>
              <SVGDashboard style={{alignItems: "center"}} name="chat" />
              <Text style={styles.signSecureSupport}>NotarizeIt Support</Text>
          </View>
          {isVisible && (
            <View>
              <Animated.View style={[styles.chatContainer, isTabletSize && styles.tabletContainer ]} >
                <TouchableOpacity onPress={(event) => event.stopPropagation()} activeOpacity={1}>
                  <Support ref={chatboxRef} setIsVisible={setIsVisible} isVisible={isVisible} />
                </TouchableOpacity>
              </Animated.View>
            </View>     
          )}
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default React.memo(HomeScreen);
