import React from 'react';
import { ScrollView, View, useWindowDimensions } from 'react-native';
import PDFViewerScreen from "../../../../components/pdf-viewer";
import { useDrawerContext } from '../../DrawerProvider';

// SCREENS
const Dashboard = React.lazy(() => import('screens/drawer-screens/dashboard'));
const Inbox = React.lazy(() => import('screens/drawer-screens/inbox'));
const Applications = React.lazy(
  () => import('screens/drawer-screens/applications')
);

const Documents = React.lazy(
  () => import('@src/screens/drawer-screens/documents')
);

const Transactions = React.lazy(
  () => import('screens/drawer-screens/transactions')
);
const Account = React.lazy(() => import('screens/drawer-screens/account'));
const DigitalSteamStamp = React.lazy(
  () => import('screens/drawer-screens/digital-seal-stamp')
);
const DigitalNotarialBook = React.lazy(
  () => import('screens/drawer-screens/digital-notarial-book')
);
const LiveNotarizationRequest = React.lazy(
  () => import('screens/drawer-screens/live-notarization-request')
);
const ContactLessRequest = React.lazy(
  () => import('screens/contactless-request')
);
const Faq = React.lazy(() => import('screens/drawer-screens/faq'));
const Support = React.lazy(() => import('screens/drawer-screens/support'));
const PartnerNotaries = React.lazy(
  () => import('screens/drawer-screens/partner-notaries')
);const Schedule = React.lazy(
  () => import('screens/drawer-screens/schedule')
);
const Signatures = React.lazy(
  () => import('screens/drawer-screens/signatures')
);
const TermsAndConditions = React.lazy(
  () => import('@src/screens/drawer-screens/terms-and-conditions')
);
const PrivacyAndPolicy = React.lazy(
  () => import('@src/screens/drawer-screens/privacy-policy')
);
const TermsAndConditionsMain = React.lazy(
  () => import('@src/screens/auth/terms-condition')
);
const PrivacyAndPolicyMain = React.lazy(
  () => import('@src/screens/auth/privacy-policy')
);
const FaceRecognitionSetup = React.lazy(
  () => import('@src/screens/drawer-screens/account/face-id-register')
);
const Notification = React.lazy(() => import('screens/drawer-screens/notification'));

//ADMIN SCREENS
const ManageUsers = React.lazy(
  () => import('screens/drawer-screens/admin/manage-users')
);

const ManageGroups = React.lazy(
  () => import('screens/drawer-screens/admin/manage-groups')
);

const AuditLog = React.lazy(
  () => import('screens/drawer-screens/admin/audit-log')
);

const DrawerItemBody = () => {
  const { height } = useWindowDimensions();
  const { activeTab } = useDrawerContext();

  const renderContent = () => {
    switch (activeTab) {
      case 'Dashboard':
        return <Dashboard />;
      case 'Applications':
        return <Applications />;
      case 'Transactions':
        return <Transactions />;
      case 'Digital Seal and Stamp':
        return <DigitalSteamStamp />;
      case 'Digital Notarial Book':
        return <DigitalNotarialBook />;
      case 'Notarization Requests':
        return <LiveNotarizationRequest />;
      case 'Partner Notaries':
        return <PartnerNotaries />;
      case 'Schedule':
        return <Schedule />;
      case 'Signatures':
        return <Signatures />;
      case 'My Account':
        return <Account />;
      case 'FAQs':
        return <Faq />;
      case 'Support':
        return <Support />;
      case 'Inbox':
        return <Inbox />;
      case 'Documents':
        return <Documents />;
      case 'Manage Users':
        return <ManageUsers />;
      case 'Manage Groups':
        return <ManageGroups />;
      case 'Audit Log':
        return <AuditLog />;
      case 'PDF Viewer':
        return <PDFViewerScreen />;
      case 'Terms & Conditions':
        return <TermsAndConditions />;
      case 'Privacy Policy':
        return <PrivacyAndPolicy />;
      case 'Terms Of Use':
        return <TermsAndConditionsMain />;
      case 'Privacy Policy':
        return <PrivacyAndPolicyMain />;
      case 'Face Recognition Setup':
        return <FaceRecognitionSetup />;
      case 'Notification':
        return <Notification />;
      case 'ContactLessRequest':
        return <ContactLessRequest />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{width: "100%", minHeight: 810, height: height}}
    >
        <React.Suspense >
          <View style={{width: "100%", minHeight: 810, height: height}}>
            {renderContent()}
          </View>
        </React.Suspense>
    </ScrollView>
  );
};

export default DrawerItemBody;
