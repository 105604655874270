import colors from '@assets/colors';
import Icon from '@expo/vector-icons/MaterialIcons';
import React from 'react';
import {
  Modal,
  Pressable,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { Button02 } from '../button';

type Props = {
  visible: boolean;
  message: string | null;
  onClose: () => void;
};

const ErrorSideway = (props: Props) => {
  const viewref = React.useRef<any>();
  const { width } = useWindowDimensions();
  const animated = useSharedValue(width);

  const onClose = () => {
    animated.value = withTiming(width, { duration: 500 });
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      props.onClose();
    }, 600);
  };

  const animateStyle = useAnimatedStyle((): any => {
    const translateX = animated.value;

    return {
      top: 15,
      transform: [{ translateX }],
    };
  }, [props.message]);

  React.useEffect(() => {
    if (viewref && viewref.current) {
      viewref.current?.measure(function (fx: any, fy: any, w: number) {
        animated.value = withTiming(width - (30 + w), { duration: 500 });
        const timeout = setTimeout(() => {
          clearTimeout(timeout);
          onClose();
        }, 3000);
      });
    }
  }, [viewref, props.visible]);

  if (!props.visible) {
    return null;
  }

  return (
    <Modal
      transparent
      visible={props.visible}
      animationType={'none'}
      onRequestClose={onClose}
      supportedOrientations={[
        'portrait',
        'portrait-upside-down',
        'landscape',
        'landscape-left',
        'landscape-right',
      ]}
    >
      <View style={styles.container}>
        <Pressable style={styles.flex1} onPress={onClose} />
        <Animated.View
          ref={viewref}
          style={[
            styles.v_modal,
            animateStyle,
          ]}
        >
          <Text style={styles.t_expired}>{props.message}</Text>
          <Button02 onPress={onClose} style={styles.btn_okay}>
            <Icon name="close" size={20} color={colors.offwhit2} />
          </Button02>
        </Animated.View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    zIndex: 2,
    backgroundColor: colors.transparent,
    ...StyleSheet.absoluteFillObject,
  },
  flex1: { flex: 1 },
  v_modal: {
    position: 'absolute',
    minHeight: 50,
    paddingHorizontal: 25,
    paddingVertical: 15,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.red2,
  },
  t_expired: {
    fontFamily: 'DMSans-Medium',
    fontSize: 18,
    color: colors.white,
    textAlign: 'center',
  },
  btn_okay: {
    marginLeft: 17,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default ErrorSideway;
