import React, { useState, useEffect, useContext } from 'react';
import { NavigationScreenProps } from '@src/navigators/NavigatorTypes';
import { ScrollView } from 'react-native-gesture-handler';
import { useNavigation } from '@react-navigation/native';
import { TextInput02 } from '@src/components/textinput';
import { useAuthService } from '@src/ducks/hooks';
import { Button01 } from '@src/components/button';
import { Text, View } from 'react-native';
import useStyles from './styles.css';
import colors from '@assets/colors';
import images from '@assets/images';
import ErrorView from './error';
import Forgot from './remember-me';
import _ from 'lodash';
import { SignInContext } from './provider';

interface ErrorProps {
  email: string;
  password: string;
}

let ErrorValue = {
  email: '',
  password: '',
};

const Login = () => {
  const styles = useStyles();
  const errorRef = React.useRef<any>(null);
  const { onSignin, isLoading } = useAuthService();
  const [email, setUsername] = useState<string>('');
  const { updateScreen } = useContext(SignInContext);
  const [password, setPassword] = useState<string>('');
  const [checkbox, setCheckbox] = useState<boolean>(false);
  const [error, setError] = useState<ErrorProps>(ErrorValue);
  const navigation = useNavigation<NavigationScreenProps<'Home'>>();

  const onCheck = () => setCheckbox(!checkbox);

  const onSigningIn = () => {
    let newError: any = {};
    if (!email) {
      newError.email = 'Email is required.';
    }
    if (!password) {
      newError.password = 'Password is required.';
    }

    setError(newError);

    if (_.isEmpty(newError)) {
      onSignin();
    }
  };

  const onSignUp = () => {
    navigation.navigate('Signup');
  };

  useEffect(() => {
    setError({} as ErrorProps);
    errorRef.current?.onClear();
  }, [email, password]);

  return (
    <View style={styles.v_signup}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={styles.v_height_top} />
        <View style={styles.signInCenter}>
          <Text style={styles.txtNote1}>Hello again!</Text>
          <Text style={styles.txtNote2}>
            We're glad you're back. Please sign in to access your account
          </Text>
        </View>
        <ErrorView ref={errorRef} />

        <View style={styles.v_marginTop20}>
          <TextInput02
            value={email}
            label="Email"
            error={error.email}
            onChangeText={setUsername}
            placeholder="Enter your email"
          />
          <TextInput02
            hasPassword
            value={password}
            label="Password"
            error={error.password}
            onChangeText={setPassword}
            placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
          />
          <Forgot isCheck={checkbox} onCheck={onCheck} />

          <Button01
            label="Sign In"
            color={colors.white}
            isLoading={isLoading}
            onPress={onSigningIn}
            style={styles.btn_signup}
            labelStyle={styles.lbl_signup}
          />
          <Button01
            label="Sign in with Face ID"
            color={colors.white}
            isLoading={isLoading}
            onPress={() => updateScreen('face-scan')}
            style={styles.btn_face_id}
            labelStyle={styles.lbl_signup}
          />

          <Button01
            label="Sign in with Google"
            source={images.google}
            color={colors.white}
            isLoading={isLoading}
            onPress={onSigningIn}
            style={styles.btn_google}
            labelStyle={styles.lbl_google}
          />
          <Text style={styles.t_signup}>
            Don’t have an account yet?
            <Text style={styles.t_signup_2} onPress={onSignUp}>
              Sign up here
            </Text>
          </Text>
        </View>
        <View style={styles.v_height} />
      </ScrollView>
    </View>
  );
};

export default Login;
