import React from 'react';
import { View } from 'react-native';
import { DrawerContext } from '@src/screens/drawer-screens/DrawerProvider';
import CreateButton from './create-button';
import NotificationButton from './notification-button';
import SearchHeader from './search-header';
import useStyles from './styles.css';
import TitleSection from './title-section';
import { useAppSelector } from '@src/ducks/ducksHook';
import { selectedAuthRole } from '@src/ducks/slices/auth.slice';

const IgnoreSearchBar = ['Terms & Conditions', 'Privacy Policy', 'FAQs'];
const IgnoreSearchBarSecond = ['signature-upload'];

const WebHeader = () => {
  const styles = useStyles();
  const role = useAppSelector(selectedAuthRole);
  const { activeTab, activeSecondScreen } = React.useContext(DrawerContext);

  return (
    <View style={styles.v_header_items}>
      <TitleSection />
      {IgnoreSearchBar.includes(activeTab) ||
      IgnoreSearchBarSecond.includes(activeSecondScreen) ? null : (
        <>
          <SearchHeader />
          {role !== 'admin' && <NotificationButton />}
          {role !== 'admin' && <CreateButton />}
        </>
      )}
    </View>
  );
};

export default WebHeader;
