import images from "@assets/images";
import DrawerList from "@src/utils/mockdata/drawer-list";
import _ from "lodash";
import React from "react";
import { Image, Text, TouchableOpacity } from "react-native";
import { DrawerContext } from "../../../DrawerProvider";
import useStyles from "./styles.css";

const TitleSection = () => {
  const styles = useStyles();
  const { activeTab, activeSecondScreen, onSetActiveSecondScreen } = React.useContext(DrawerContext);
  
  const Title = React.useMemo(() => {
    return _.find(DrawerList, { "name": activeTab })?.page ?? activeTab;
  },[activeTab]);

  const backLabel = React.useMemo(() => {
    switch(activeSecondScreen){
    case "inbox-details":
      return "Back to Inbox";
    }
  },[activeSecondScreen]);

  return _.isEmpty(backLabel) ? 
    <Text style={styles.t_title}>{Title}</Text> :
    <TouchableOpacity activeOpacity={0.9} style={styles.button_back} 
      onPress={() => onSetActiveSecondScreen("")}>
      <Image style={{width: 21, height: 11}} source={images.ic_back} resizeMode="contain"/>
      <Text style={styles.t_title_back}>{backLabel}</Text>
    </TouchableOpacity>;
};

export default TitleSection;
