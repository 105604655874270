import React, { memo } from 'react';
import { View, Image, Text, Pressable } from 'react-native';
import useStyles from './styles';

type DataItemProps = {
  value: string;
  source: any;
  style?: any;
  textStyle?: any;
  iconStyle?: any;
  disabled?: boolean;
  onPress?: () => void;
};

const CustomCell = (props: DataItemProps) => {
  const styles = useStyles();

  return (
    <Pressable
      onPress={props.onPress}
      disabled={!props.disabled}
      style={[styles.v_center, props.style]}
    >
      <Image
        source={props.source}
        style={props.iconStyle}
        resizeMode="contain"
      />
      <Text numberOfLines={2} style={props.textStyle}>
        {props.value}
      </Text>
    </Pressable>
  );
};

export default memo(CustomCell);
