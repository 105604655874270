import Request from "./Request";
import { API_BASE_URL, API_IP_URL, API_MAYA_URL } from "@env";

// API CORE
export const CurrentApi = new Request(API_BASE_URL);
export const MayaAPI = new Request(API_MAYA_URL);

export const setToken = (token: string) => {
  CurrentApi.setToken(token);
};

export const callGet = async (routes: string, token?: string, responseType: string | undefined = "json") => {
  try {
    if (token) {
      CurrentApi.setToken(token);
    }
    if (responseType) {
      CurrentApi.setResponseType(responseType);
    }
    CurrentApi.setContentType("");
    const result = await CurrentApi.get(`${routes}`);

    return result;
  } catch (e) {
    throw e;
  }
};

export const callPost = async (
  routes: string,
  params: any,
  token?: string | undefined,
  responseType: string | undefined = "json"
) => {
  try {
    if (token) {
      CurrentApi.setToken(token);
    }
    if (responseType) {
      CurrentApi.setResponseType(responseType);
    }
    CurrentApi.setContentType("");
    const result = await CurrentApi.post(`${routes}`, params);

    return result;
  } catch (e) {
    throw e;
  }
};

export const callPatch = async (
  routes: string,
  params: any,
  token?: string | undefined,
  responseType: string | undefined = "json"
) => {
  try {
    if (token) {
      CurrentApi.setToken(token);
    }
    if (responseType) {
      CurrentApi.setResponseType(responseType);
    }
    CurrentApi.setContentType("");

    const result = await CurrentApi.patch(`${routes}`, params);

    return result;
  } catch (e) {
    throw e;
  }
};

export const callPut = async (
  routes: string,
  params: any,
  token?: string | undefined,
  responseType: string | undefined = "json"
) => {
  try {
    if (token) {
      CurrentApi.setToken(token);
    }
    if (responseType) {
      CurrentApi.setResponseType(responseType);
    }
    CurrentApi.setContentType("");
    const result = await CurrentApi.put(`${routes}`, params);

    return result;
  } catch (e) {
    throw e;
  }
};

export const callFormData = async (
  routes: string,
  params: any,
  token?: string | undefined,
  responseType: string | undefined = "json"
) => {
  try {
    if (token) {
      CurrentApi.setToken(token);
    }
    if (responseType) {
      CurrentApi.setResponseType(responseType);
    }
    CurrentApi.setContentType("multipart/form-data");

    const result = await CurrentApi.post(`${routes}`, params);

    return result;
  } catch (e) {
    throw e;
  }
};

export const callIpInfo = async () => {
  const response = await fetch(API_IP_URL);

  return response.text().catch(() => {
    return { message: "Success" };
  });
};

export const callDelete = async (
  routes: string,
  token?: string | undefined
) => {
  try {
    if (token) {
      CurrentApi.setToken(token);
    }

    const result = await CurrentApi.delete(`${routes}`);

    return result;
  } catch (e) {
    throw e;
  }
};

export const callMayaGet = async (routes: string, token?: string) => {
  try {
    if (token) {
      MayaAPI.setAuthorization(token);
    }
    MayaAPI.setResponseType("json");
    const result = await MayaAPI.get(`${routes}`);

    return result;
  } catch (e) {
    throw e;
  }
};

export const callMayaPost = async (
  routes: string,
  params: any,
  token?: string | undefined,
) => {
  try {
    if (token) {
      MayaAPI.setAuthorization(token);
    }
    MayaAPI.setResponseType("json");
    const result = await MayaAPI.post(`${routes}`, params);

    return result;
  } catch (e) {
    throw e;
  }
};

export const callMayaDelete = async (routes: string, token?: string) => {
  try {
    if (token) {
      MayaAPI.setAuthorization(token);
    }
    MayaAPI.setResponseType("json");
    const result = await MayaAPI.delete(`${routes}`);

    return result;
  } catch (e) {
    throw e;
  }
};
