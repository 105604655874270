import React from 'react';
import CopyRightContent from '../signin/copy-right';
import Background from '../signin/background';
import Header from '../signin/header';
import useStyles from './styles.css';
import { View } from 'react-native';
import Logo from '../signin/logo';
import Screens from './screens';
import { mobileSizeChecker } from '@src/utils/screensize-helper';

const SignIn = () => {
  const styles = useStyles();

  const isMobile = mobileSizeChecker();
  return (
    <>
    {isMobile ? (
          <View style={styles.container}>
          <Background />
          <Header />
          <View style={styles.v_body}>
            <Logo />
            <Screens />
          </View>
          <CopyRightContent />
        </View>
    ) : (
          <View style={styles.container}>
      <Background />
      <Header />
      <View style={styles.v2_body}>
        <Logo />
        <Screens />
      </View>
      {/* <CopyRightContent /> */}
    </View>
    )}
  </>
  );
};

export default SignIn;
