import { StyleSheet } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles';
import TypographyStyle from '@assets/typography';
import colors from '@assets/colors';

const useStyles = CreateResponsiveStyle(
  {
    v_header_items: {
      flex: 1,
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    button_back: {flex: 1, flexDirection: "row", alignItems: "center"},
    t_title_back: {
      fontSize: 16,
      lineHeight: 24,
      marginLeft: 20,
      color: colors.grayPrimary,
      fontFamily: "DMSans-Medium",
    },
    t_title: {
      flex: 1,
      fontSize: 30,
      lineHeight: 14,
      color: colors.black,
      fontFamily: "DMSans-Bold",
    },
    header_search: {
      flex: 0.6,
      flexDirection: "row",
    },
    inputStyle: {flex: 1, borderRadius: 8 },
    input_search: {
      width: 500,
      height: 47,
      marginTop: 0,
      borderRadius: 8,
      borderWidth: 1,
      marginRight: 30,
      borderColor: colors.grayPrimary,
      backgroundColor: colors.white,
    },
    btn_createdocument: {
      height: 47,
      minWidth: 210,
      borderRadius: 8,
      backgroundColor: colors.purple4,
      marginLeft: 25,
    },
    btn_label: {
      color: colors.white,
      fontSize: 14,
      fontWeight: "700",
      fontFamily: "Montserrat",
      lineHeight: 16,
      marginRight: 7
    },
    v_create_request: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    ti_borderStyle: { borderRadius: 8, marginTop: 0, borderWidth: 0, width: 224 },
    icon_notif: {
      top: 0,
      right: 2,
      height: 9,
      width: 9,
      borderRadius: 10,
      position: 'absolute',
      backgroundColor: colors.red1,
    },
    modalNotary: {
        position: 'absolute',
        right: '17%',
        top: '5%',
        width: 400,
        height: 700,
        bgcolor: 'background.paper',
        border: '1px rgba(240, 240, 240, 1)',
        p: 4,
        zIndex: -1,
        borderRadius: 4,
    },
    modalPrincipal: {
        position: 'absolute',
        right: '5%',
        top: '5%',
        width: 400,
        height: 700,
        bgcolor: 'background.paper',
        border: '1px rgba(240, 240, 240, 1)',
        p: 4,
        zIndex: -1,
        borderRadius: 4,
    },
 
    container: { flex: 1, width: '100%', paddingTop: 20, paddingHorizontal: 0, },
    header_label: StyleSheet.flatten([
      TypographyStyle.heading4Regular,
      { textAlign: 'left', color: colors.black3, marginVertical: 18 },
    ]),
    v_separator: {
      height: 0,
    },
    v_height: { height: 50 },
    inbox_row: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    inbox_status: {
      width: 8,
      height: 8, 
      borderRadius: 4, 
      backgroundColor: colors.red1,
      marginHorizontal: 20,
    },
    inbox_status_viewed: {
      width: 8,
      height: 8, 
      borderRadius: 4, 
      backgroundColor: colors.transparent,
      marginHorizontal: 20,
    },
    inbox_content: {
      flex: 1,
      marginVertical: 10,
    },
    inbox_text: {
      padding: 2,
      fontSize: 13,
    },
    btn_upload: { marginLeft: 20, height: 40 },
  },
  {
    [DEVICE_SIZES.LG]: {
      t_title: { fontSize: 22.5, lineHeight: 10.5 },
      input_search: { width: 300, height: 35.25, borderRadius: 6 },
      inputStyle: { fontSize: 10.5, lineHeight: 10.5, borderRadius: 6 },
      btn_createdocument: { minWidth: 157.5, height: 35.25, borderRadius: 6 },
      btn_label: { fontSize: 12, lineHeight: 10 },
    },
    [DEVICE_SIZES.MD]: {
      btn_createdocument: {
        paddingVertical: 10,
        paddingHorizontal: 18,
        borderRadius: 8,
        marginTop: 10,
        width: "90%",
        alignItems: "center",
        alignContent: "center",
        alignSelf: "center"
      },
      btn_label: {
        color: colors.white,
        fontSize: 12,
        fontWeight: "700",
        fontFamily: "Montserrat",
        lineHeight: 16,
      },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      v_header_items: {
        flex: 1,
        flexDirection: "column-reverse",
        alignItems: "center",
      },
      header_search: {
        flex: 1,
        justifyContent: "flex-end",
        width: "100%",
      },
      v_create_request: {
        flex: 1,
        width: "100%",
        flexDirection: "row",
        justifyContent: "flex-end",
      },
      input_search: {
        marginTop: 20,
        marginRight: 21,
        width: "90%",
        marginLeft:15, 
        marginBottom: 12,
      },
      t_title: {
        display:"none"
      },
      btn_createdocument: {
        marginTop: 20,
        paddingVertical: 20,
        paddingHorizontal: 15,
        borderRadius: 8,
        width: "92%",
        marginLeft:15,
        marginBottom: 20
      },
      btn_label: {
        color: colors.white,
        fontSize: 12,
        fontWeight: "700",
        fontFamily: "Montserrat",
        lineHeight: 16,
      },
    },
  }
);

export default useStyles;
