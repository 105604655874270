import { CreateResponsiveStyle } from 'rn-responsive-styles';

const useStyles = CreateResponsiveStyle(
  {
    drawer_item_container: {
      flex: 1,
      overflow: 'hidden',
    },
  },
);

export default useStyles;
