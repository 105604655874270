/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { View, Text, ScrollView } from 'react-native';
import { Button01 } from '@src/components/button';
import useStyles from './styles.css';
import Header from './header';
import TermsConditionBody from './body';

const TermsCondition = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const btnLabel = 'Go to dashboard';

  const onPress = () => {
    navigation.navigate('Home');
  };

  return (
    <View style={styles.container}>
      <Header />
      <View style={styles.v_body}>
      <Text style={styles.t_title}>Terms & Condition</Text>
      <TermsConditionBody />
        </View>
      <View style={{ height: 50 }} />
    </View>
  );
};

export default TermsCondition;
