import { StyleSheet } from 'react-native';
import colors from '@assets/colors';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import TypographyStyle from '@assets/typography';

const useStyles = CreateResponsiveStyle(
  {
    drawer_item_header: {
      height: 91,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingVertical: 5,
      elevation: 10,
      borderBottomWidth: 0.5,
      borderBottomColor: colors.border5,
    },
    drawer_mobile_container: {
      flex: 1,
      flexWrap: 'wrap',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    drawer_mobile_logo: {
      height: 32,
      width: 115,
      tintColor: colors.white,
    },
    icon_notif: {
      top: 0,
      height: 9,
      width: 9,
      borderRadius: 10,
      position: 'absolute',
    },
    v_header_items: {
      flex: 1,
      flexWrap: 'wrap',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    header_search: {
      flex: 0.6,
      flexDirection: 'row',
    },
    input_search: {
      flex: 1,
      marginTop: 0,
      marginRight: 21,
      borderRadius: 8,
      backgroundColor: colors.white,
    },
    btn_search: {
      height: 42,
      borderRadius: 8,
      marginRight: 30,
      backgroundColor: colors.lightPurple,
    },
    btn_label: {
      color: colors.white,
      fontSize: 14,
      fontWeight: '700',
      fontFamily: 'Montserrat',
      lineHeight: 16,
    },
    v_create_request: {
      flex: 0.4,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    drawer_mobile_notif: {
      flex: 1,
      // marginTop: 25,
      marginRight: 15,
      flexWrap: 'wrap',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    drawer_mobile_textnotif: {
      fontFamily: 'DMSans',
      fontWeight: '700',
      fontSize: 14,
      lineHeight: 21,
      color: colors.purple,
      marginLeft: 12,
      textDecorationLine: 'underline',
    },
    header_profile_picture: {
      width: 48,
      height: 48,
      borderRadius: 48 / 2,
    },
    ti_borderStyle: { borderRadius: 8, marginTop: 0, borderWidth: 0 },
    relative: { position: 'relative' },

    // LIVE HEADER
    container: {
      position: 'absolute',
      top: 0,
      width: '100%',
      height: 91,
      paddingHorizontal: 30,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.purple,
      shadowOpacity: 1,
      shadowColor: 'rgba(82, 82, 82, 0.1)',
      shadowOffset: { height: 6, width: 0 },
      paddingVertical: 5,
      shadowRadius: 12,
      elevation: 10,
    },
    btnIcon: {
      position: 'absolute',
      left: 64,
      zIndex: 2,
    },
    steps_container: {
      width: '50%',
      height: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
    },
    steps_outline: {
      flex: 1,
      position: 'absolute',
      backgroundColor: colors.lightPurple,
      justifyContent: 'center',
      height: 1,
      top: 32,
    },
    steps_fillLine: {
      flex: 1,
      position: 'absolute',
      backgroundColor: colors.white,
      justifyContent: 'center',
      height: 1,
      top: 32,
    },
    v_align_center: { alignItems: 'center' },
    outer_circle: {
      backgroundColor: colors.purple,
      paddingRight: 15,
      paddingLeft: 19,
      paddingVertical: 10,
      borderRadius: 50,
    },
    number_circle: {
      height: 28,
      width: 28,
      borderRadius: 20,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    steps_number: {
      fontFamily: 'DMSans',
      fontWeight: '700',
      fontSize: 14,
      lineHeight: 14,
      color: colors.lightPurple,
    },
    steps_label: {
      fontFamily: 'DMSans',
      fontWeight: '700',
      fontSize: 10,
      lineHeight: 17,
      color: colors.lightPurple,
    },
    pdf_header_container: {
      position: 'absolute',
      top: 0,
      width: '100%',
      height: 91,
      paddingHorizontal: 30,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: colors.purple,
      shadowOpacity: 1,
      shadowColor: 'rgba(82, 82, 82, 0.1)',
      shadowOffset: { height: 6, width: 0 },
      paddingVertical: 5,
      shadowRadius: 12,
      elevation: 10,
    },
    pdf_docs_pages_v: {
      width: '70%',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    pdf_docs_pages_wrapper: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      width: '60%',
      justifyContent: 'space-evenly',
    },
    pdf_zoom_text_v: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      backgroundColor: colors.white,
      paddingVertical: 14,
      paddingHorizontal: 16,
      alignItems: 'center',
      borderRadius: 8,
    },
    pdf_zoom_text: StyleSheet.flatten([
      TypographyStyle.Body1Regular,
      { color: colors.black3, marginRight: 16 },
    ]),
    pdf_btn_export: {
      padding: 12,
      borderWidth: 1,
      borderColor: colors.white,
      borderRadius: 8,
      alignItems: 'center',
      justifyContent: 'center',
    },
    pdf_buttons_v: {
      flex: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    pdf_btn_img: { width: 16, height: 16 },
  },
  {
    [DEVICE_SIZES.MD]: {
      drawer_item_header: {
        marginRight: 0,
        marginLeft: 0,
        paddingHorizontal: 30,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: colors.purple,
        shadowOpacity: 1,
        shadowColor: 'rgba(82, 82, 82, 0.1)',
        shadowOffset: { height: 6, width: 0 },
        paddingVertical: 5,
        shadowRadius: 12,
        elevation: 10,
        height: 60,
        paddingLeft: 20,
        paddingRight: 20,
        justifyContent: 'flex-end',
      },
      btn_search: {
        paddingVertical: 10,
        paddingHorizontal: 18,
        borderRadius: 8,
      },
      btn_label: {
        color: colors.white,
        fontSize: 12,
        fontWeight: '700',
        fontFamily: 'Montserrat',
        lineHeight: 16,
      },
      header_profile_picture: {
        height: 40,
        width: 40,
        borderRadius: 40 / 2,
      },
    },
    [DEVICE_SIZES.SM]: {
      drawer_item_header: {
        marginRight: 0,
        marginLeft: 0,
        paddingHorizontal: 30,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: colors.purple,
        shadowOpacity: 1,
        shadowColor: 'rgba(82, 82, 82, 0.1)',
        shadowOffset: { height: 6, width: 0 },
        paddingVertical: 5,
        shadowRadius: 12,
        elevation: 10,
        height: 60,
        paddingLeft: 20,
        paddingRight: 20,
        justifyContent: 'flex-end',
      },
      v_header_items: {
        flex: 1,
        flexDirection: 'column-reverse',
        alignItems: 'center',
      },
      header_search: {
        flex: 1,
        justifyContent: 'flex-end',
        width: '100%',
      },
      v_create_request: {
        flex: 1,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'flex-end',
      },
      input_search: {
        flex: 0.8,
        marginTop: 0,
        marginRight: 21,
      },
      btn_search: {
        paddingVertical: 8,
        paddingHorizontal: 10,
        borderRadius: 8,
      },
      btn_label: {
        color: colors.white,
        fontSize: 12,
        fontWeight: '700',
        fontFamily: 'Montserrat',
        lineHeight: 16,
      },
      header_profile_picture: {
        height: 30,
        width: 30,
        borderRadius: 30 / 2,
      },
    },
    [DEVICE_SIZES.XS]: {
      drawer_item_header: {
        marginRight: 0,
        marginLeft: 0,
        paddingHorizontal: 30,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: colors.purple,
        shadowOpacity: 1,
        shadowColor: 'rgba(82, 82, 82, 0.1)',
        shadowOffset: { height: 6, width: 0 },
        paddingVertical: 5,
        shadowRadius: 12,
        elevation: 10,
        height: 60,
        paddingLeft: 20,
        paddingRight: 20,
        justifyContent: 'flex-end',
      },

      v_header_items: {
        flex: 1,
        flexDirection: 'column-reverse',
        alignItems: 'center',
      },
      header_search: {
        flex: 1,
        justifyContent: 'flex-end',
        width: '100%',
      },
      v_create_request: {
        flex: 1,
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
      },
      input_search: {
        flex: 0.8,
        marginTop: 0,
        marginRight: 21,
      },
      btn_search: {
        paddingVertical: 8,
        paddingHorizontal: 10,
        borderRadius: 8,
      },
      btn_label: {
        color: colors.white,
        fontSize: 12,
        fontWeight: '700',
        fontFamily: 'Montserrat',
        lineHeight: 16,
      },
      header_profile_picture: {
        height: 30,
        width: 30,
        borderRadius: 30 / 2,
      },
    },
  }
);

export default useStyles;
