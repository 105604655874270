import { SagaIterator } from "@redux-saga/core";
import {
  DocumentFormDataParser,
} from "@src/utils/formdata-helper";
import { call, delay, put, select, takeEvery, takeLatest } from "redux-saga/effects";

// HELPER
import {
  affixSignature4Contactless,
  completeSignatureWithCreateManifest,
  submitOnlyAffixSignature,
} from "@src/utils/signature-helper";

// API
import {
  createMayaCustomer,
  generateMayaToken,
  getIpInfo,
  linkGeneratedMayaTokenToCustomer,
  paymayaTransaction,
  signsecureCompleteDocument,
  signsecureCreateDocument,
  signsecureDownloadDocument,
  signsecureSignDocument,
  signsecureViewDocument
} from "@src/utils/api";

// Slice
import { WEB_BASE_URL } from "@env";
import { checkPartiesSigned } from "@src/utils/filter-helper";
import DOCSTAMP from "@src/utils/mockdata/doc-stamp";
import Downloadjs from "downloadjs";
import _ from "lodash";
import moment from "moment";
import { authActions, selectedAuthAccessToken, selectedAuthLoginType, selectedAuthRole } from "../slices/auth.slice";
import { selectedViewDocument, signsecureActions } from "../slices/signsecure.slice";
import { selectUserDetails, userActions } from "../slices/user.slice";
import { CreateLiveRequestValue } from "../types";

function* handleCreateLiveRequest(action: {
  type: typeof signsecureActions.createLiveRequest,
  payload: CreateLiveRequestValue,
}): SagaIterator {
  try {
    const ip = yield call(getIpInfo);
    const accessToken = yield select(selectedAuthAccessToken);
    if(action.payload.requestType === "Contactless"){
      const loginType = yield select(selectedAuthLoginType);
      const uri = yield call(affixSignature4Contactless,
        action.payload.uri,
        action.payload
      );
      const body = yield call(DocumentFormDataParser, 
        {...action.payload, loginType, uri: `data:application/pdf;base64,${uri}`}, ip);
      const result = yield call(signsecureCreateDocument, body, accessToken);
      yield put(signsecureActions.viewDocumentSuccess({...result.data, uri: `data:application/pdf;base64,${uri}`}));
    }else{
      const body = yield call(DocumentFormDataParser, action.payload, ip);
      const result = yield call(signsecureCreateDocument, body, accessToken);
  
      console.log("resultresult", result)
    }
    
    yield put(signsecureActions.createLiveRequestSuccess(true));
    yield delay(1000);
    yield put(signsecureActions.createLiveRequestSuccess(false));
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    yield put(signsecureActions.createLiveRequestFailed({ message }));

    if (message.includes("Unauthorized")) {
      yield put(userActions.failed({ message }));
      yield put(authActions.logout());
    }
  }
}

function* handleContactlessPayment(action: {
  type: typeof userActions.upgradeUserSubscription;
  payload: UpgradeSubscription;
}): SagaIterator {
  try {
    const userDetails = yield select(selectUserDetails);
    const accessToken = yield select(selectedAuthAccessToken);
    const customerParams = {
      "contact": {
        "phone": userDetails.phoneNumber === "null" ? "" : userDetails.phoneNumber,
        "email": userDetails.email
      },
      "firstName": userDetails.givenName,
      "middleName": "",
      "lastName": userDetails.lastName,
      "customerSince": moment(userDetails.createdAt).format("YYYY-MM-DD")
    };
    const customer = yield call(createMayaCustomer, customerParams);
    const token = yield call(generateMayaToken, {card: action.payload.card });
    const cardToken = yield call(linkGeneratedMayaTokenToCustomer, {
      customerId: customer.id,
      isDefault: true,
      paymentTokenId: token.paymentTokenId
    });
    const transaction = yield call(paymayaTransaction, customer.id, cardToken.cardTokenId, {
      totalAmount: {
        amount: action.payload.cost,
        currency: "PHP"
      },
      redirectUrl: {
        success: `${WEB_BASE_URL}/payment-success`,
        failure: `${WEB_BASE_URL}/payment-failed`,
      },
      requestReferenceNumber: action.payload.id,
      metadata: {
        pf: {
          smi: userDetails.id,
          smn: `${userDetails.givenName} ${userDetails.lastName}`,
          mci: userDetails.office,
          mpc: "PHP",
          mco: "PHL",
        },
      subMerchantRequestReferenceNumber: "",
      },
    });

    window.location.href = transaction.verificationUrl;
    yield put(userActions.upgradeUserSubscriptionSuccess(transaction));
    yield put(userActions.updateUserSuccess(userResult.data));
  } catch (error: any) {
    if(error.parameters && typeof error.parameters === "object"){
      yield put(userActions.upgradeUserSubscriptionFailed({ message: error.parameters[0].description }));
      yield delay(1000);
      yield put(userActions.upgradeUserSubscriptionFailed({}));
    }else{
      const message = error.message || error.error || "Something went wrong";
      if (message.includes("Unauthorized")) {
        yield put(authActions.logout());
      }
      yield put(userActions.upgradeUserSubscriptionFailed({ message }));
      yield delay(1000);
      yield put(userActions.upgradeUserSubscriptionFailed({}));
    }
  }
}

function* handleViewDocument(): SagaIterator {
  try {
    const role = yield select(selectedAuthRole);
    const document = yield select(selectedViewDocument);
    const accessToken = yield select(selectedAuthAccessToken);
    if(_.isEmpty(document)){
      yield put(signsecureActions.viewDocumentFailed({}));
      return;
    }

    const result = yield call(
      signsecureViewDocument,
      document.id || document._id,
      accessToken
    );
    const base64 = yield call(
      signsecureDownloadDocument,
      document.id || document._id,
      accessToken
    );

    if(["ACCEPTED"].includes(document.workflow.status) && role === 'notary'){
      let signatures = result.data.workflow.signatures;
      signatures[signatures.length - 1] =  signatures[signatures.length - 1].concat(DOCSTAMP);
    }
    
    yield put(signsecureActions.viewDocumentSuccess({ ...result.data, uri: base64 }));
    yield put(signsecureActions.refreshDocumentRequest());
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    yield put(signsecureActions.viewDocumentFailed({ message }));

    if (message.includes("Unauthorized")) {
      yield put(userActions.failed({ message }));
      yield put(authActions.logout());
    }
    yield delay(1000);
    yield put(signsecureActions.viewDocumentFailed({}));
  }
}

function* refreshDocumentWorker(): SagaIterator {
  try {
    // Delay for 15 seconds before triggering the refresh again
    yield delay(15000);    
    const document = yield select(selectedViewDocument);
    console.log("documentdocument", document)
    const accessToken = yield select(selectedAuthAccessToken);

    if(_.isEmpty(document)){
      return;
    }

    const result = yield call(
      signsecureViewDocument,
      document.id,
      accessToken
    );

    const base64 = yield call(
      signsecureDownloadDocument,
      document.id,
      accessToken
    );
    const signatures = document.workflow.signatures;
    const payload = { ...result.data, uri: base64 };
    payload.workflow.signatures = signatures;

    yield put(signsecureActions.updateSignatures(payload));

    // Restart the token refresh process by calling the same worker saga recursively
    yield call(refreshDocumentWorker);
  } catch (error) { /* empty */ }
}

function* handleVideoSession(): SagaIterator {
  try {
    const document = yield select(selectedViewDocument);
    const base64 = yield call(
      submitOnlyAffixSignature,
      document.uri,
      document
    );

    const user = yield call(
      completeSignatureWithCreateManifest,
      base64,
      document
    );


    const blob = new Blob([user], { type: "application/pdf" });
    Downloadjs(
      blob,
      "files.pdf",
      "application/pdf"
    );

    yield put(signsecureActions.affixSignatureFailed({}));
    return;

    yield put(
      signsecureActions.submitVideoSessionSuccess(
        `data:application/pdf;base64,${user}`
      )
    );
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    yield put(signsecureActions.submitVideoSessionFailed({ message }));

    if (message.includes("Unauthorized")) {
      yield put(userActions.failed({ message }));
      yield put(authActions.logout());
    }
    yield delay(1000);
    yield put(signsecureActions.affixSignatureFailed({}));
  }
}

function* handleAffixSignature(): SagaIterator {
  try {
    const document = yield select(selectedViewDocument);
    const loginType = yield select(selectedAuthLoginType);
    const accessToken = yield select(selectedAuthAccessToken);

    const base64 = yield call(submitOnlyAffixSignature,
      document.uri,
      document
    );

    const ip = yield call(getIpInfo);

    // Create a Blob from the Uint8Array
    const blob = new Blob([base64], { type: "application/pdf" });
    const signature = document.signature || {};
    // Append the Blob to the FormData
    const formData = new FormData();
    formData.append("file", blob, `${document.name}.pdf`);
    formData.append("ip", ip);
    formData.append("loginType", loginType);
    formData.append("signatureType", signature.signatureType);
    formData.append("signatureId", signature.signatureId);

    const result = yield call(
      signsecureSignDocument,
      document.id,
      formData,
      accessToken
    );

    const isCompleted = yield call(checkPartiesSigned, result.data);

    if(isCompleted){
      const uri = yield call(
        signsecureDownloadDocument,
        result.data.id,
        accessToken
      );
      const base64_complete = yield call(
        completeSignatureWithCreateManifest,
        uri,
        result.data
      );
      const newblob = new Blob([base64_complete], { type: "application/pdf" });
      const formDataComplete = new FormData();
      formDataComplete.append("file", newblob, `${result.data.name}.pdf`);
      const completed = yield call(
        signsecureCompleteDocument,
        result.data.id,
        formDataComplete,
        accessToken
      );
      
      yield put(signsecureActions.affixSignatureSuccess(completed.data));
      return;
    }

    yield put(signsecureActions.affixSignatureSuccess(result.data));
  } catch (e: any) {
    console.log("Error", e);
    const message = e.message || e.error || "Something went wrong";
    yield put(signsecureActions.affixSignatureFailed({ message }));
    
    if (message.includes("Unauthorized")) {
      yield put(userActions.failed({ message }));
      yield put(authActions.logout());
    }
    yield delay(1000);
    yield put(signsecureActions.affixSignatureFailed({}));
  }
}

// Watcher Saga
function* signsecureWatcherSaga(): SagaIterator {
  yield takeEvery(signsecureActions.createLiveRequest.type, handleCreateLiveRequest);
  yield takeEvery(signsecureActions.submitVideoSession.type, handleVideoSession);
  yield takeEvery(signsecureActions.viewDocumentRequest.type, handleViewDocument);
  yield takeEvery(signsecureActions.affixSignatureRequest.type, handleAffixSignature);
  yield takeLatest(signsecureActions.refreshDocumentRequest.type, refreshDocumentWorker);
}

export default signsecureWatcherSaga;
