import React, { useState } from 'react';
import { Pressable, View, Text } from 'react-native';
import useStyles from './styles.css';
import { Tooltip01Types } from './types';

const Tooltip = (props: Tooltip01Types) => {
  const styles = useStyles();
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Pressable
      onHoverIn={() => setIsVisible(true)}
      onHoverOut={() => setIsVisible(false)}
    >
      {props.children}
      {isVisible && (
        <View style={[styles.tooltip, props.style]}>{props.label || ''}</View>
      )}
    </Pressable>
  );
};

export default React.memo(Tooltip);
