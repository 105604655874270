import React, { useState, useContext, useEffect } from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import { useInputHelper } from '@src/utils/inputs-helper';
import { TextInput02 } from '@src/components/textinput';
import { useNavigation } from '@react-navigation/native';
import { useSignupService } from '@src/ducks/hooks';
import { Button01 } from '@src/components/button';
import { SignupContext } from './SignupProvider';
import { SignupInput } from '@src/ducks/types';
import { Text, View } from 'react-native';
import Icon from "@expo/vector-icons/MaterialIcons";
import BackButton from './back-button';
import UploadMask from './upload-mask';
import useStyles from './styles.css';
import colors from '@assets/colors';
import Validator from 'validator';
import Terms from './terms';
import { Tooltip01 } from '@src/components/tooltip';
import _ from 'lodash';

const initialInputState = {
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: '',
  citizenship: '',
  email: '',
  phoneNumber: '',
  mobile: '',
  province: '',
  country: '',
  city: '',
  residentialAddress: '',
  placeOfBusiness: '',
  password: '',
  confirmpassword: '',
  rollNo: '',
  ibpNo: '',
  mcleNo: '',
  tinNo: '',
  adminMatterNo: '',
  validIdUrl: {},
  ibpIdUrl: {},
  professionalTaxReceipt: {},
  passportPhoto: {},
  goodMoralCertificate: {},
  role: 'principal',
} as SignupInput;

const Principal = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const [terms, setTerms] = useState<boolean>(false);
  const { updateScreen } = useContext(SignupContext);
  const { data, failed, isLoading, onSignup } = useSignupService();
  const { state, onDispatch } = useInputHelper(initialInputState);
  const [error, setError] = useState<SignupInput>({} as SignupInput);

  const onChangeText = (type: string) => (value: string) => {
    onDispatch(type)(value);
    setError({} as SignupInput);
  };

  const handleSubmit = () => {
    const newErr: any = {} as SignupInput;

    const requiredFields = [
      'firstName',
      'lastName',
      'country',
      'province',
      'city',
      'email',
      'password',
      'confirmpassword',
      'validIdUrl',
    ];

    requiredFields.forEach((field) => {
      if (_.isEmpty(state[field])) {
        newErr[field] = 'This field is required';
      }
    });

    if (state.email && !Validator.isEmail(state.email)) {
      newErr.email = 'Invalid email format';
    }

    if (state.password !== state.confirmpassword) {
      newErr.confirmpassword = 'Passwords do not match';
    }

    if (state.password.length <= 5) {
      newErr.password = 'Password must be at least six characters';
    }

    console.log(state)
    // onSignup(state);
    setError(newErr);
    if (_.isEmpty(newErr)) {
      onSignup(state);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(failed)) {
      setError({ message: failed.message } as SignupInput);
    }
  }, [failed]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      updateScreen('emailsent-principal');
    }
  }, [data]);

  return (
    <View style={styles.v_signup}>
      <ScrollView>
        <View style={styles.v_height} />
        {/* <BackButton /> */}
        <View style={styles.signInCenter}>
          <Text style={styles.txtNote1}>Create an account</Text>
          <Text style={styles.t_publicnotary}>
            You are signing up as principal
          </Text>
          <Text style={styles.txtNote2}>
            Enter your name as it appears on your government-issued ID
          </Text>
        </View>
        <View>
          <View style={styles.v_wrap_row}>
            <TextInput02
              label="First Name"
              value={state.firstName}
              error={error.firstName}
              style={styles.v_flex1}
              placeholder="Your First Name"
              onChangeText={onChangeText('firstName')}
            />
            <View style={styles.v_divider} />
            <TextInput02
              label="Middle Name (optional)"
              style={styles.v_flex1}
              value={state.middleName}
              error={error.middleName}
              placeholder="Your Middle Name"
              onChangeText={onChangeText('middleName')}
            />
            <View style={styles.v_divider} />
            <TextInput02
              label="Last Name"
              style={styles.v_flex1}
              value={state.lastName}
              error={error.lastName}
              placeholder="Your Last Name"
              onChangeText={onChangeText('lastName')}
            />
          </View>

          <View style={styles.v_wrap_row}>
            <TextInput02
              label="Country"
              value={state.country}
              error={error.country}
              style={styles.v_flex1}
              placeholder="Philippines"
              onChangeText={onChangeText('country')}
            />
          </View>

          <View style={styles.v_wrap_row}>
            <TextInput02
              label="Province"
              value={state.province}
              error={error.province}
              style={styles.v_flex1}
              placeholder="National Capital Region (NCR)"
              onChangeText={onChangeText('province')}
            />
            <View style={styles.v_divider} />
            <TextInput02
              label="City"
              value={state.city}
              error={error.city}
              style={styles.v_flex1}
              placeholder="Quezon City"
              onChangeText={onChangeText('city')}
            />
          </View>
          <View style={{flexDirection: 'row'}}>
            <UploadMask
              label="Upload Valid ID"
              value={state.validIdUrl.name}
              error={Boolean(error.validIdUrl)}
              onSource={onDispatch('validIdUrl')}
            />
            <Tooltip01
            label={`We accept government-issued \n ID cards like Driver's License, \n SSS ID, PhilHealth ID, UMID \n Company ID, etc.`}
            >
              <Icon name="help-outline" size={16} color={colors.gray7} style={{marginTop: 22, paddingLeft: 10, opacity: 1}} />
            </Tooltip01>
          </View>

          <View style={styles.v_wrap_row}>
            <TextInput02
              label="Email"
              value={state.email}
              error={error.email}
              style={styles.v_flex1}
              placeholder="Enter your email"
              onChangeText={onChangeText('email')}
            />
          </View>

          <View style={styles.v_wrap_row}>
            <TextInput02
              hasPassword
              label="Password"
              style={styles.v_flex1}
              value={state.password}
              error={error.password}
              placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
              onChangeText={onChangeText('password')}
            />
            <View style={styles.v_divider} />
            <TextInput02
              hasPassword
              style={styles.v_flex1}
              label="Confirm Password"
              value={state.confirmpassword}
              error={error.confirmpassword}
              placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
              onChangeText={onChangeText('confirmpassword')}
            />
          </View>

          <Terms isCheck={terms} onCheck={() => setTerms(!terms)} />
          <Button01
            label="Sign Up"
            color={colors.white}
            isLoading={isLoading}
            onPress={handleSubmit}
            style={styles.btn_signup}
            labelStyle={styles.lbl_signup}
            disabled={!terms}
          />
          {!_.isEmpty(error.message) && (
            <Text style={styles.t_error_message}>{error.message}</Text>
          )}
          <Text style={styles.t_signup}>
            Already have an account?
            <Text
              onPress={() => navigation.navigate('Signin')}
              style={styles.t_terms}
            >
              {' '}
              Login
            </Text>
          </Text>
        </View>
        <View style={styles.v_height} />
      </ScrollView>
    </View>
  );
};

export default Principal;
